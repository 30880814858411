import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plan: [],
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setPlan: (state, { payload }) => {
      state.plan = payload;
    },
  },
});

export const { setPlan } = planSlice.actions;
export const planSelector = (state) => state.plan;
export default planSlice.reducer;
