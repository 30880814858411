import React, { useEffect } from "react";
// Ant Design
import { Button, Space, Form, Input, Checkbox, message } from "antd";
// DNR JSX
import { Header } from "./screenHeader.js";
import Badges from "./badges.js";
import ScreenFooter from "./screenFooter.js";
// Router
import { useNavigate } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
// API
import { postEligibility } from "../api.js";

function Eligibility() {
  const { contact } = useSelector(contactSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const steps = [
    { completed: true },
    { completed: true },
    { completed: false },
    { completed: false },
    { completed: false },
  ];
  // Event Handler for submit
  const handleFinish = (values) => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        const data = {
          userenrollmentid: contact?.userenrollmentid,
          dob: `${values.day}-${values.month}-${values.year}`,
          contact_declaration: values.checked === true ? 1 : 0,
          ssn: values.ssnno,
        };
        postEligibility(data)
          .then(() => {
            // dispatch(addContactInfo({ data: data }));
            navigate("/governmentprogram");
          })
          .catch((err) => {
            message.error(err.response.data.message);
          });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  // Event Handler for back btn
  const handleBackBtn = () => {
    navigate("/contact");
  };

  // Validation rules
  const validateCheck = (_, value) => {
    if (!value || isNaN(value)) {
      return Promise.reject("agree to continue");
    }
    return Promise.resolve();
  };
  const validateDay = (_, value) => {
    if (!value) {
      return Promise.reject("Invalid day");
    }
    const day = parseInt(value, 10);
    if (isNaN(day) || day < 1 || day > 31) {
      return Promise.reject("Invalid day");
    }
    return Promise.resolve();
  };

  const validateMonth = (_, value) => {
    if (!value) {
      return Promise.reject("Invalid month");
    }
    const month = parseInt(value, 10);
    if (isNaN(month) || month < 1 || month > 12) {
      return Promise.reject("Invalid month");
    }
    return Promise.resolve();
  };

  const validateYear = (_, value) => {
    if (!value) {
      return Promise.reject("Invalid year");
    }
    const year = parseInt(value, 10);
    if (isNaN(year) || year < 1900 || year > new Date().getFullYear()) {
      return Promise.reject("Invalid year");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    // Handle Date Split
    const destructureDate = () => {
      const dateStr = contact?.dob;
      const dateObj = new Date(dateStr);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      form.setFieldsValue({
        day: !isNaN(day) ? day : "",
        month: !isNaN(month) ? month : "",
        year: !isNaN(year) ? year : "",
        ssnno: contact?.ssn,
        checked: contact?.contact_declaration === 1 ? true : false,
      });
    };
    destructureDate();
  }, [form]);

  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        <div className="cov-wrapper">
          {/* card container view */}
          <div className="cov-container" style={{ backgroundColor: "#fff" }}>
            <div className="con-title px-1">ELIGIBILITY</div>
            <div className="eli-subcontent px-2">
              Your information is kept confidential and only used for the
              approval process through our secure platform
            </div>
            <Form
              className="px-2"
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={handleFinish}
            >
              <div className="eli-dob-label">DATE OF BIRTH</div>
              <Space>
                {/* month */}
                <Form.Item
                  name="month"
                  rules={[
                    {
                      required: true,
                      validator: validateMonth,
                    },
                  ]}
                >
                  {/* {month} */}
                  <Input placeholder="MM" maxLength={2} />
                </Form.Item>
                {/* Day */}
                <Form.Item
                  name="day"
                  rules={[
                    {
                      required: true,
                      validator: validateDay,
                    },
                  ]}
                >
                  <Input placeholder="DD" maxLength={2} />
                </Form.Item>
                {/* year */}
                <Form.Item
                  name="year"
                  rules={[
                    {
                      required: true,
                      validator: validateYear,
                    },
                  ]}
                >
                  <Input placeholder="YYYY" maxLength={4} />
                </Form.Item>
              </Space>
              <Form.Item
                name="ssnno"
                label="LAST 4 NUMBERS OF SSN"
                rules={[
                  {
                    pattern: new RegExp("^[0-9]{4}$"),
                    required: true,
                    message: "please enter last 4 number",
                  },
                ]}
              >
                <Input maxLength={4} className="con-input" />
              </Form.Item>
              <Form.Item
                name="checked"
                valuePropName="checked"
                rules={[
                  {
                    validator: validateCheck,
                    required: true,
                  },
                ]}
              >
                <Checkbox>
                  I consent to Excess Telecom contacting me via Email, SMS, and
                  or phone call only if necessary to complete my application.
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Space className="d-flex justify-content-center mt-5 mb-3">
                  <Button
                    className="con-b-btn"
                    htmlType="reset"
                    onClick={handleBackBtn}
                  >
                    Back
                  </Button>
                  <Button
                    className={`con-btn enabled`}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            <ul className="dot-steps">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className={`step ${step.completed ? "completed" : ""}`}
                ></li>
              ))}
            </ul>
          </div>
          {/* -------------------------------- */}
        </div>
        {/* Badges */}
        <Badges />
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default Eligibility;
