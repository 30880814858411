import React, { useState, useEffect } from "react";
// Ant Design
import { Button, Spin, message, Modal } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// REDUX
import { useSelector } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
// API
import { postEnrollment } from "../api.js";

function NationalVerifier() {
  const navigate = useNavigate();
  const { contact } = useSelector(contactSelector);
  const [loading, setLoading] = useState(false);
  const [nvLink, setNvLink] = useState(sessionStorage.getItem("nvlink"));
  const [errortext, setErrortext] = useState("");
  const [isError, setError] = useState(false);
  const[isPending, setPending] = useState(false);

  // Event Handler for Submit action
  const handleSubmit = () => {
    setLoading(true);
    if (nvLink !== undefined) {
      console.log("to national verifier");
      setLoading(false);
    } else {
      message.error("Something went wrong please try later");
      setLoading(false);
    }
  };
  //   Event Handler for Retry action
  const handleRetry = () => {
    setLoading(true);
    postEnrollment({ userenrollmentid: contact?.userenrollmentid })
      .then((res) => {
        const nvLink = res.data.data.link;
        console.log(nvLink);
        console.log(res);
        if (res?.data?.data?.status === "PENDING_RESOLUTION") {
          sessionStorage.setItem("nvlink", res.data.data.link);
          // Change back national verifier path
          // navigate to device just for demo
          // navigate("/device");
          message.warning(res?.data?.data?.status);
          setError(true);
          setErrortext(res?.data?.data?.status);

          setLoading(false);
          //navigate("/nationalverifier");
        }
        else if (res?.data?.data?.status === "PENDING_ELIGIBILITY") {
          sessionStorage.setItem("nvlink", res.data.data.link);
          // Change back national verifier path
          // navigate to device just for demo
          // navigate("/device");
          setLoading(false);
          message.warning(res?.data?.data?.status);
          setError(true);
          setErrortext(res?.data?.data?.status);

          setLoading(false);
          
        }
        else if (res?.data?.data?.status === "PENDING_CERT") {
          sessionStorage.setItem("nvlink", res.data.data.link);
          // Change back national verifier path
          // navigate to device just for demo
          // navigate("/device");
          setLoading(false);
          message.warning("Application is pending for Verification");
          setError(true);
          setErrortext("Application is pending for Verification");
        }
        else if (res?.data?.data?.status === "PENDING_REVIEW") {
          setLoading(false);
          //setPending(true);
          message.warning(res?.data?.data?.status);
          setError(true);
          setErrortext(res?.data?.data?.status);
        } else if (res.data.data.includes("not eligible to receive a device")) {
          setLoading(false);
          Modal.error({
            title: 'Customer is already registered with this information and, start a new enrollment process.',
            okText: "Start New Enrollment",
            onOk() {
              navigate("/");
            },
            
          });   
        } else if (
          res.data.message.includes("Eligibity Verification Completed")
        ) {
          setLoading(false);
          setError(false);
          navigate("/device");
        }
        else if (res.data.message.includes ("Subscriber passed all validations and verifications")) {
          setLoading(false);
          setError(false);
          navigate("/device");
        } 
        else {
          message.error(
            res?.data?.data?.status ?? "Something went wrong please try later"
          );
          setError(true);
          setErrortext(
            res?.data?.data?.status ?? "Something went wrong please try later"
          );
        }
      })
      .catch((err) => {
        message.error(err.response.data.message[0]);
        // navigate("/nationalverifier");
        console.log(err);
        sessionStorage.setItem("nvlink", err.response.data.link);
        setLoading(false);
      });
  };
  useEffect(() => {
    const getLink = () => {
      setNvLink(sessionStorage.getItem("nvlink"));
      // nvLink.current = sessionStorage.getItem("nvlink");
      console.log(nvLink);
    };
    getLink();
  }, [nvLink]);

  return (
    <>
      <div className=" cov-wrapper">
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <Spin spinning={loading}>
            <div className="cov-title">Congratulations!</div>
            <div className="cov-subcontent mt-2 mb-2 px-3">
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                You're Pre-Approved
              </span>
            </div>
            <p className="mt-3 text-center">
              To finalize your process immediately,you must complete your
              missing information in the National Verifier so that the
              Government can confirm your eligibility
            </p>
            <div className="coverage-img text-center mt-3">
              <img
                src={"assets/approved-image/pre-approved.png"}
                alt="pre-approved"
                className="px-3"
              />
            </div>
            <div className="text-center">
              {isError && (
                <p className="text-danger" style={{ fontSize: "15px" }}>
                  **{errortext}**
                </p>
              )}
            </div>
            { isPending === false && (
            <div className="d-flex justify-content-center">
              <Button
                className="mt-4 text-center nv-com-btn"
                href={nvLink}
                target="_blank"
                onClick={handleSubmit}
              >
                Complete My Application
              </Button>
            </div> )}
            <div className="mt-3 d-flex justify-content-center">
              <Button
                type="link"
                className="nv-com-retry"
                onClick={handleRetry}
                style={{ fontSize: "13px" }}
              >
                I competed my National Verifier, Retry
              </Button>
            </div>
          </Spin>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default NationalVerifier;
