import { Header } from "./screenHeader.js";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import ScreenFooter from "./screenFooter.js";

function SuccessInfo() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ ");
  };
  return (
    <>
      <div className=" cov-wrapper">
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <div className="suc-title mt-4">CONGRATULATIONS</div>
          <div className="suc-subcontent mt-3 mb-4 px-2">
          Congratulations! Your enrollment application has been successfully processed, and you're all set up with Excess! Enjoy your new service!
          </div>
          <div className="coverage-img text-center">
            <img
              src={"assets/approved-image/approved-image.png"}
              alt="edit-icon"
              className="px-3"
            />
          </div>
          <p className="text-center mt-4 px-3 mx-0 lh-1">
            You will receive an email with follow-up information.
          </p>
          <Button className="suc-btn mb-auto" onClick={handleClick}>
            Finish
          </Button>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default SuccessInfo;
