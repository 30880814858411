import React, { useEffect, useRef, useState } from "react";
// Ant Design
import { Button, Space, Form, Input, message, Checkbox, Spin } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { coverageSelector } from "../store/reducers/coverageSlice.js";
import { contactSelector, setContact } from "../store/reducers/contactSlice.js";
import { setCoverage } from "../store/reducers/coverageSlice.js";
// API
import { postContactDetials, getReviewDetails } from "../api.js";

function Contact() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [enrollId, setEnrollId] = useState("");
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const { coverage } = useSelector(coverageSelector);
  const { contact } = useSelector(contactSelector);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const steps = [
    { completed: true },
    { completed: false },
    { completed: false },
    { completed: false },
  ];

  const scrollStyle = {
    overflowY: "scroll",
    maxHeight: "470px",
  };

  // Event Handler to shift input focus
  const handleInputChange = (e, ref) => {
    const input = e.target.value;
    if (input.length === 2) {
      ref.current?.focus();
    }
  };
  // Event handler for input change
  const handlePhoneNumberChange = (event) => {
    // Remove any non-digit characters from the input
    const rawInput = event.target.value.replace(/\D/g, "");
    let formattedNumber = "";
    for (let i = 0; i < rawInput.length && i < 10; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += "-";
      }
      formattedNumber += rawInput[i];
    }

    // Update the state with the formatted number
    form.setFieldsValue({ phonenumber: formattedNumber });
  };

  // Validation rules
  const validateCheck = (_, value) => {
    if (value === true) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (!value || isNaN(value)) {
      return Promise.reject("Agree to continue");
    }
    return Promise.resolve();
  };
  const validateDay = (_, value) => {
    if (!value) {
      return Promise.reject("Invalid Day");
    }
    const day = parseInt(value, 10);
    if (isNaN(day) || day < 1 || day > 31) {
      return Promise.reject("Invalid Day");
    }
    return Promise.resolve();
  };

  const validateMonth = (_, value) => {
    if (!value) {
      return Promise.reject("Invalid Month");
    }
    const month = parseInt(value, 10);
    if (isNaN(month) || month < 1 || month > 12) {
      return Promise.reject("Invalid Month");
    }
    return Promise.resolve();
  };

  const validateYear = (_, value) => {
    if (!value) {
      return Promise.reject("Invalid Year");
    }
    const year = parseInt(value, 10);
    if (isNaN(year) || year < 1900 || year > new Date().getFullYear()) {
      return Promise.reject("Invalid Year");
    }
    return Promise.resolve();
  };

  //Event handler for form submit
  const handleFinish = (values) => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setLoading(true);
        const formatPhNos = values.phonenumber.replace(/-/g, "");
        const dob = new Date(`${values.year}-${values.month}-${values.day}`);
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getUTCFullYear() - 18);

        if (dob <= eighteenYearsAgo) {
          console.log(eighteenYearsAgo)
          // The person is at least 18 years old
          const contactInfo = {
            userenrollmentid: contact?.userenrollmentid,
            email: values.email,
            firstname: values.firstname,
            middlename: values.middlename ?? "",
            lastname: values.lastname,
            phone: Number(formatPhNos),
            dob: `${values.year}-${values.month}-${values.day}`,
            contact_declaration: values.checked === true ? 1 : 0,
            ssn: values.ssnno.toString(),
            zipcode: coverage?.zipcode.toString(),
          };
          console.log("contactinfo", contactInfo);
          // API call add Contact info
          postContactDetials(contactInfo)
            .then((res) => {
              dispatch(
                setContact({
                  ...contactInfo,
                  userenrollmentid: res.data.data.user_enrollmentid,
                })
              );
              navigate("/governmentprogram");
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              message.error(err.response.data.message);
            });
        } else {
          setLoading(false);
          console.log(eighteenYearsAgo)
          message.error("You must be at least 18 years old to apply");
          // The person is less than 18 years old
        }
      
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const handleChange = () => {
    const values = form.getFieldsValue();
    if (values?.checked === true) {
      setChecked(values?.checked);
      const formatPhNos = values.phonenumber.replace(/-/g, "");
      const contactInfo = {
        userenrollmentid: contact?.userenrollmentid ?? enrollId,
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        middlename: values.middlename ?? "",
        phone: Number(formatPhNos),
        contact_declaration: values.checked === true ? 1 : 0,
        zipcode: coverage?.zipcode.toString(),
      };
      // API call add Contact info
      postContactDetials(contactInfo)
        .then((res) => {
          console.log(res.data.data);
          setEnrollId(res.data.data.user_enrollmentid);
          dispatch(
            setContact({
              ...contactInfo,
              userenrollmentid: res.data.data.user_enrollmentid,
            })
          );
        })
        .catch((err) => message.error(err.response.data.message));
    }
  };
  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const urlId = urlParams.get("userenrollment");
    console.log(urlId);
    // Fetch user data based on the userId
    getReviewDetails({
      userenrollmentid: contact?.userenrollmentid ?? urlId,
    })
      .then((res) => {
        console.log(res);
        const resp = res.data.data;
        dispatch(setContact(resp));
        dispatch(setCoverage(resp));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // message.error(err.response.data.message);
        return;
      });
  }, []);

  useEffect(() => {
    console.log(coverage);
    // Handle Date Split
    const renderReduxData = () => {
      // Format Number
      const rawInput = String(contact?.phone).replace(/\D/g, "");
      let formattedNumber = "";
      for (let i = 0; i < rawInput.length && i < 10; i++) {
        if (i === 3 || i === 6) {
          formattedNumber += "-";
        }
        formattedNumber += rawInput[i];
      }
      // Spit date
      const dateStr = contact?.dob;
      // const dateObj = new Date(dateStr);
      // const day = dateObj.getUTCDate(); // Day of the month
      // const month = dateObj.getUTCMonth() + 1; // Months are zero-based in JavaScript
      // const year = dateObj.getUTCFullYear();

      //console.log(`Day: ${day}, Month: ${month}, Year: ${year}`);
      const dateObj = new Date(dateStr);
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getUTCDate()).padStart(2, "0");
      form.setFieldsValue({
        firstname: contact?.firstname,
        lastname: contact?.lastname,
        middlename: contact?.middlename ?? "",
        email: contact?.email,
        phonenumber: formattedNumber,
        day: !isNaN(day) ? day : "",
        month: !isNaN(month) ? month : "",
        year: !isNaN(year) ? year : "",
        checked: contact?.contact_declaration === 1 ? true : false,
        ssnno: contact?.ssn,
      });
    };
    renderReduxData();
  }, [form]);

  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <div className="con-title px-1">CONTACT INFORMATION</div>
          <div className="con-subcontent px-2">
            Fill out the contact details below to get started
          </div>
          <Spin spinning={loading}>
            <Form
              className={"px-2 custom-x-scrollbar"}
              style={scrollStyle}
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={handleFinish}
            >
              <div className="row">
                <Form.Item
                  name="firstname"
                  label="First Name"
                  type="text"
                  className="col"
                  rules={[
                    {
                      required: true,
                      message: "First Name required",
                    },
                  ]}
                >
                  <Input maxLength={26} />
                </Form.Item>
                <Form.Item
                  name="middlename"
                  label="Middle Name"
                  type="text"
                  className="col"
                  rules={[
                    {
                      required: false,
                      message: "Middle Name required",
                    },
                  ]}
                >
                  <Input className="con-input" />
                </Form.Item>
              </div>
              <div className="row">
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  type="text"
                  className="col"
                  rules={[
                    {
                      required: true,
                      message: "Last Name required",
                    },
                  ]}
                >
                  <Input className="con-input" />
                </Form.Item>
              </div>
              <Form.Item
                name="email"
                label="Email"
                style={{
                  marginTop: "-6px",
                }}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "enter valid email address",
                  },
                ]}
              >
                <Input maxLength={72} />
              </Form.Item>
              <Form.Item
                name="phonenumber"
                label="Phone Number"
                style={{
                  marginTop: "-6px",
                }}
                rules={[
                  {
                    pattern: new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{4}$"),
                    required: true,
                    message: "enter valid Phone Number",
                  },
                ]}
              >
                <Input onChange={handlePhoneNumberChange} maxLength={12} />
              </Form.Item>
              <Form.Item
                name="checked"
                valuePropName="checked"
                style={{
                  marginTop: "-10px",
                }}
                onChange={handleChange}
                rules={[
                  {
                    validator: validateCheck,
                    required: true,
                  },
                ]}
              >
                <Checkbox disabled={checked}>
                  By checking this box and continuing your application you
                  consent to Excess Telecom verifying your eligibility for the
                  ACP, Lifeline, and other services. By entering the phone
                  number and email above, I authorize and give express consent
                  to being contacted by Excess Telecom or its affiliate for
                  marketing purposes during the Application Process. I may be
                  contacted for this purpose via email, telephone, or text
                  messaging, including calls or messages using an automated
                  telephone dialing system, manually, or with
                  pre-recorded/artificial voice messages. I also provide express
                  consent to be contacted, regardless of whether my number is
                  listed on the Do-Not-Call Registry or a state equivalent
                  registry. Consent to such contacts is not a condition of
                  service and may be revoked at any time. Message and data rates
                  may apply. Message frequency varies. For contacts received via
                  text message, reply HELP for help or STOP to cancel, or click
                  the unsubscribe link (where available) in any text message. I
                  will receive a one-time opt-out confirmation text message. I
                  may also revoke consent by following the opt-out instructions
                  in an email or text message. For more information see Terms
                  and Conditions and Privacy Policy
                </Checkbox>
              </Form.Item>
              <div className="divider"></div>
              <div
                ref={ref}
                className="con-title px-1"
                style={{ marginBottom: "-4px", marginTop: "-10px" }}
              >
                GET APPROVED
              </div>
              <div
                className="eli-subcontent px-2"
                style={{ marginBottom: "-6px" }}
              >
                Your information is kept confidential and only used for the
                approval process through our secure platform
              </div>
              <div className="eli-dob-label">DATE OF BIRTH</div>
              <Space>
                {/* month */}
                <Form.Item
                  name="month"
                  rules={[
                    {
                      required: true,
                      validator: validateMonth,
                    },
                  ]}
                >
                  {/* {month} */}
                  <Input
                    placeholder="MM"
                    maxLength={2}
                    onChange={(e) => handleInputChange(e, input2Ref)}
                    ref={input1Ref}
                  />
                </Form.Item>
                {/* Day */}
                <Form.Item
                  name="day"
                  rules={[
                    {
                      required: true,
                      validator: validateDay,
                    },
                  ]}
                >
                  <Input
                    placeholder="DD"
                    maxLength={2}
                    onChange={(e) => handleInputChange(e, input3Ref)}
                    ref={input2Ref}
                  />
                </Form.Item>
                {/* year */}
                <Form.Item
                  name="year"
                  rules={[
                    {
                      required: true,
                      validator: validateYear,
                    },
                  ]}
                >
                  <Input placeholder="YYYY" maxLength={4} ref={input3Ref} />
                </Form.Item>
              </Space>
              <Form.Item
                name="ssnno"
                label="LAST 4 NUMBERS OF SSN"
                style={{ marginTop: "-14px !important" }}
                rules={[
                  {
                    pattern: new RegExp("^[0-9]{4}$"),
                    required: true,
                    message: "Please enter last 4 number",
                  },
                ]}
              >
                <Input.Password maxLength={4} className="con-input" />
              </Form.Item>
              <Form.Item>
                <Space className="d-flex justify-content-center mb-2">
                  <Button
                    className="con-b-btn"
                    htmlType="reset"
                    disabled={true}
                  >
                    Back
                  </Button>
                  <Button
                    className="con-btn enabled"
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Space>
              </Form.Item>
              <ul className="dot-steps">
                {steps.map((step, index) => (
                  <li
                    key={index}
                    className={`step ${step.completed ? "completed" : ""}`}
                  ></li>
                ))}
              </ul>
            </Form>
          </Spin>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default Contact;
