/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { MobileOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, DatePicker } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { login_with_otp, request_for_resent_otp } from "../api.js";
import logo from "../asset/logo.png";
import { message } from "antd";
import moment from "moment";

function PersonalInfo() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [office, setOffice] = useState("");
  const [searchParams] = useSearchParams();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [dob, setDob] = useState(null);
  // const candidate = JSON.parse(localStorage.getItem("Candidate_values"));
  // const candidate_id = JSON.parse(localStorage.getItem("candidate_id"));
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const onBack = () => {
    console.log("in continue");
    setloading(true);
    navigate("/addressinfo");
    setloading(false);
  };
  const onFinish = (values) => {
    console.log(
      "in continue",
      values,
      "dob",
      dob,
      "converted dob",
      moment(dob).format("YYYY-MM-DD")
    );
    // localStorage.setItem("Candidate_values", JSON.stringify(values));
    setloading(true);
    // localStorage.setItem(
    //   "candidate_id",
    //   JSON.stringify(values.zip)
    // );
    navigate("/disclosures");
    setloading(false);
    message.success("Personal info is valid");

    // message.success("Zipcode is valid");
    // setloading(true);
    // console.log(values.otp.join(""));
    // var obj_arr = {
    //   candidate_id: candidate_id,
    //   otp: values.otp.join(""),
    // };
    // login_with_otp(obj_arr)
    //   .then((res) => {
    //     console.log(res);
    //     setUser(res?.data?.items);
    //     localStorage.setItem("wuser", JSON.stringify(res?.data?.items));
    //     setloading(false);
    //     message.success("Welcome back!");
    //     navigate("/onboarding");
    //   })
    //   .catch((err) => {
    //     setloading(false);
    //     message.error("Invalid credentials!");
    //   });
  };

  //  const handleStartOpenChange = (open) => {
  //     if (!open) {
  //       this.setState({ endOpen: true });
  //     }
  //   };

  const onDobChange = (value) => {
    console.log(`dob`, value); // moment(value).format('YYYY-MM-DD'))
    setDob(value); //moment(value).format('YYYY-MM-DD'));
  };

  // const otpchange = (e) => {
  //   console.log(e);
  // };
  // const resendOTP = () => {
  //   setloading(true);
  //   var obj_arr = {
  //     candidate_id: candidate_id.toString(),
  //     mobile_number: candidate.mobile_number,
  //   };
  //   request_for_resent_otp(obj_arr)
  //     .then((res) => {
  //       console.log(res);
  //       setMinutes(0);
  //       setSeconds(30);
  //       setloading(false);
  //       message.success(res.data.message);
  //     })
  //     .catch((err) => {
  //       setloading(false);
  //       message.error("Request Timeout!");
  //     });
  // };
  return (
    <>
      <div className="container rounded-3xl p-6 pb-8 mt-6">
        <div className="flex flex-col">
          <div
            _ngcontent-dmy-c30=""
            className=" sm:mx-auto sm:max-w-md mb-auto"
          >
            <div _ngcontent-dmy-c30="" className="flex justify-center py-8">
              <img
                _ngcontent-dmy-c30=""
                src="assets/images/Excess-Logo-White.webp"
                alt="Excess Telecom"
              />
            </div>
            <header>Enrollment Form</header>
            <div className="progress-bar">
              <div className="step">
                <p>Contact</p>
                <div className="bullet">
                  <span>1</span>
                </div>
                <div className="check fas fa-check"></div>
              </div>
              <div className="step">
                <p>Program</p>
                <div className="bullet">
                  <span>2</span>
                </div>
                <div className="check fas fa-check"></div>
              </div>
              <div className="step">
                <p>Address</p>
                <div className="bullet">
                  <span>3</span>
                </div>
                <div className="check fas fa-check"></div>
              </div>
              <div className="step">
                <p className="active">Personal</p>
                <div className="bullet active">
                  <span>4</span>
                </div>
                <div className="check fas fa-check"></div>
              </div>
              <div className="step">
                <p>Agree</p>
                <div className="bullet">
                  <span>5</span>
                </div>
                <div className="check fas fa-check"></div>
              </div>
            </div>
            <div>
              <div className="page slide-page">
                <Form
                  // name="normal_login"
                  className="ng-dirty ng-invalid ng-touched"
                  // initialValues={{
                  //   office_number: searchParams.get("office"),
                  //   password: "",
                  //   remember: false,
                  // }}
                  onFinish={onFinish}
                >
                  {/* <form  noValidate="" className="ng-touched ng-dirty ng-invalid"> */}
                  <div
                    id="personal-info"
                    formgroupname="personalInfoForm"
                    className="ng-untouched ng-pristine ng-invalid"
                  >
                    {/* <h3  className="text-center mb-2 font-medium text-2xl cursor-default">
                      Validation
                    </h3> */}
                    <div className="text-start text-sm text-lightFormText mt-2 text-center cursor-default">
                      Your information is only used to review eligibility.
                    </div>
                    <div className="text-start mt-4 mb-2">
                      <label className="form-label">Date of Birth *</label>
                      <div className="text-start mt-4 mb-2">
                        {/* flex flex-row gap-4"> */}
                        {/* <select  name="dobMM" formcontrolname="dobMM" className="border-accentColor border w-full h-10 pl-2 cursor-pointer ng-untouched ng-pristine ng-invalid">
                          <option  value="" defaultValue="" disabled="">
                            MM
                          </option>
                          <option  value="01">
                            January
                          </option>
                          <option  value="02">
                            February
                          </option>
                          <option  value="03">
                            March
                          </option>
                          <option  value="04">
                            April
                          </option>
                          <option  value="05">
                            May
                          </option>
                          <option  value="06">
                            June
                          </option>
                          <option  value="07">
                            July
                          </option>
                          <option  value="08">
                            August
                          </option>
                          <option  value="09">
                            September
                          </option>
                          <option  value="10">
                            October
                          </option>
                          <option  value="11">
                            November
                          </option>
                          <option  value="12">
                            December
                          </option>
                        </select><select  name="dobDD" formcontrolname="dobDD" className="border-accentColor border w-full h-10 pl-2 cursor-pointer ng-untouched ng-pristine ng-invalid">
                          <option  value="" defaultValue="" disabled="">
                            DD
                          </option>
                        </select>
                        <select  name="dobYYYY" formcontrolname="dobYYYY" className="border-accentColor border w-full h-10 pl-2 cursor-pointer ng-untouched ng-pristine ng-invalid">
                          <option  value="" defaultValue="" disabled="">
                            YYYY
                          </option>
                        </select> */}
                        {/* <Form.Item
                          name="dob_field"
                          getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                          // getValueProps={(i) => ({value: moment(i)})}
                          rules={[
                            // {
                            //   required: true,
                            //   message: "Please input date of birth",
                            //   // type: "email"
                            //   // minLength: 2
                            //   // maxLength: 10
                            // },
                            {
                              pattern: new RegExp(/^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/),
                              message:
                                'Must be a valid date!',
                            },
                            // {
                            //   validator: (_, value) => {
                            //     // let age = (bdate, now = new Date(), then = new Date(bdate)) => now.getFullYear() - then.getFullYear() - (now < new Date(now.getFullYear(), then.getMonth(), then.getDate()))
                            //     // const userAge = age(value, new Date(Date.now()).toLocaleDateString('fr-CA'))
                            //     console.log(`value`, value)
                            //     // var today = new Date();
                            //     // var today_month = today.getMonth() + 1; //STRANGE NUMBERING //January is 0!
                            //     // var userAge = today.getYear() - value.getYear();

                            //     // if ((today_month > value.getMonth() || ((today_month === value.getMonth()) && (today.getDate() < value.getDate())))) {
                            //     //   userAge--;
                            //     // }
                            //     const strs = value.split("-");
                            //     var dd = strs[2];
                            //     var mm = strs[1];
                            //     var yy = strs[0];

                            //     var d = new Date();
                            //     var ds = d.getDate();
                            //     var ms = d.getMonth();
                            //     var ys = d.getFullYear();
                            //     var accepted_age = 18;

                            //     var days = ((accepted_age * 12) * 30) + (ms * 30) + ds;
                            //     var userAge = (((ys - yy) * 12) * 30) + ((12 - mm) * 30) + parseInt(30 - dd);
                            //     console.log(`age`, userAge,'days',days)
                            //     if ((days - userAge) <= '0') {
                            //       console.log((days - userAge));
                            //       return Promise.reject(new Error("Age must be greater than or equal to 18"))
                            //     } else {
                            //       console.log((days - userAge));
                            //       return Promise.resolve()
                            //     }
                            //     // if (userAge < 18) {
                            //     //   Promise.reject(new Error("Age must be greater than or equal to 18"))
                            //     // } else {
                            //     //   Promise.resolve()
                            //     // }
                            //   }
                            // }
                          ]}
                        > */}
                        <DatePicker
                          value={dob}
                          // disabledDate={this.disabledStartDate}
                          format="YYYY-MM-DD"
                          placeholder="Select Date of Birth"
                          // minDate={moment().subtract(150, "years")._d}
                          // maxDate={moment().subtract(18, 'years')._d}
                          disabledDate={(d) =>
                            !d ||
                            d.isAfter(
                              moment().subtract(18, "years")._d
                              // Date.now() + +1000 * 60 * 60 * 24 * 30 * 6
                            ) ||
                            d.isBefore(moment().subtract(150, "years")._d)
                          }
                          onChange={onDobChange}
                          className="border-accentColor border w-full h-10 pl-2 ng-untouched ng-pristine ng-invalid"
                          // onOpenChange={handleStartOpenChange}
                        />
                        {/* </Form.Item> */}
                      </div>
                    </div>
                    <div className="text-start mt-4 mb-2">
                      <label htmlFor="ssn" className="form-label mb-0">
                        Last 4 numbers of SSN *
                      </label>
                      <div className="text-sm text-lightFormText mt-0 mb-2 cursor-default">
                        4 digits are required
                      </div>
                      <Form.Item
                        name="ssn"
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Please input Shipping Address Line2",
                          //   // minLength: 2
                          //   // maxLength: 5
                          // },
                          {
                            validator: (_, value) =>
                              value === undefined ||
                              value === null ||
                              value.length <= 0
                                ? Promise.reject(new Error("Please input SSN"))
                                : value.length === 4
                                ? Promise.resolve()
                                : Promise.reject(new Error("Must be 4 digits")),
                          },
                        ]}
                      >
                        <Input
                          className="border-accentColor border w-full h-10 pl-2 ng-untouched ng-pristine ng-invalid"
                          type="number"
                          placeholder="SSN"
                          id="ssn"
                          onPaste={() => {
                            return false;
                          }}
                          onCopy={() => {
                            return false;
                          }}
                          onCut={() => {
                            return false;
                          }}
                        />
                      </Form.Item>
                      {/* <input  type="text" maxLength="4" onInput={() => { "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" }} id="ssn" aria-describedby="ssn" formcontrolname="ssn" className="border-accentColor border w-full h-10 pl-2 ng-untouched ng-pristine ng-invalid" /> */}
                    </div>
                  </div>
                  <br />
                  <div id="buttons" className="flex flex-row gap-4">
                    {/* <Form.Item> */}
                    <Button
                      type="button"
                      onClick={onBack}
                      className="bg-lightGrey w-full h-10 text-textsDarkGrey rounded mt-5"
                    >
                      {/* <div className="flex flex-row" style={{ textShadow: "0px 1px 3px rgba(139, 189, 0, 0.4)" }}> */}
                      Back
                      {/* </div> */}
                    </Button>
                    {/* </Form.Item>
                    <Form.Item> */}
                    <Button
                      id="blueAction"
                      type="submit"
                      htmlType="submit"
                      // onClick={onFinish}
                      className="bg-buttonGreen w-full h-10 text-white rounded mt-5 hover:bg-buttonGreenHover hover:shadow-greenExcess duration-150"
                    >
                      {/* <div className="flex flex-row" style={{ textShadow: "0px 1px 3px rgba(139, 189, 0, 0.4)" }}> */}
                      Next
                      {/* </div> */}
                    </Button>
                    {/* </Form.Item> */}
                    {/* <button  type="button" onClick={onBack} className="bg-lightGrey w-full h-10 text-textsDarkGrey rounded mt-5">
                      Back
                    </button>
                    <button  id="blueAction" type="submit" onClick={onFinish} className="bg-buttonGreen w-full h-10 text-white rounded mt-5 hover:bg-buttonGreenHover hover:shadow-greenExcess duration-150">
                      Next
                    </button> */}
                  </div>
                  <div className="text-sm text-lightFormText mt-6 text-center cursor-default">
                    {/* Page 4 of 5 */}
                  </div>
                  {/* </form> */}
                </Form>
              </div>
              <div
                _ngcontent-dmy-c30=""
                className="flex flex-row justify-center gap-3 mb-4"
              >
                <img
                  _ngcontent-dmy-c30=""
                  src="../assets/images/fully-secured-ssl.svg"
                  alt="secured"
                  className="max-w-142"
                />
                <img
                  _ngcontent-dmy-c30=""
                  src="../assets/images/acp.webp"
                  alt="acp"
                  className="h-9/10 my-auto"
                />
              </div>
            </div>
            <div id="footer">
              {/* className="bg-accentColor text-mainColor"> */}
              <div className="text-start sm:mx-auto sm:max-w-md px-4 py-2">
                <p
                  // className="font-normal leading-2 mb-4 mt-4 cursor-default"
                  style={{ fontSize: "14px" }}
                >
                  * Excess Telecom Enrollment Partner is a third-party
                  distributor for Excess Telecom and is responsible for the
                  content and creation of this advertisement. Excess Telecom is
                  a service provider for the government-funded Affordable
                  Connectivity Program (ACP). The ACP is a government benefit
                  program operated by the Federal Communications Commission
                  (FCC) that provides discounts on monthly broadband Internet
                  access services and certain connected devices. The ACP
                  provides discounts of up to $30 per eligible household on
                  monthly broadband Internet access service (or up to $75 per
                  eligible household on Tribal lands) and up to $100 on certain
                  connected devices (with a required co-payment of between $10
                  and $50). ACP service and device discounts cannot be
                  transferred to another household or individual. Eligible
                  households are limited to one monthly service discount and one
                  device discount. Rates and device discounts are available for
                  qualified customers only. Visit{" "}
                  <a
                    _ngcontent-dmy-c30=""
                    href="https://www.excesstelecom.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u _ngcontent-dmy-c30="">www.excesstelecom.com</u>
                  </a>{" "}
                  for full eligibility requirements, service agreements,
                  coverage map, and program details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInfo;
