import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eligible: [],
};

const eligibleSlice = createSlice({
  name: "eligible",
  initialState,
  reducers: {
    setEligible: (state, { payload }) => {
      state.eligible = payload;
    },
  },
});

export const { setEligible } = eligibleSlice.actions;
export const eligibleSelector = (state) => state.eligible;
export default eligibleSlice.reducer;
