import React, { useEffect, useState } from "react";
// Ant Design
import { Button, Space, Form, Input, message, Spin } from "antd";
// React Cards
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import { planListSelector } from "../store/reducers/planItemSlice.js";
import { paymentDetailSelector } from "../store/reducers/paymentDetailSlice.js";
// API
import { makePayment, stripeIntent } from "../api.js";
// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.js";

function PaymentInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [cardType, setCardType] = useState(null);
  const { contact } = useSelector(contactSelector);
  const { planList } = useSelector(planListSelector);
  const { paymentDetail } = useSelector(paymentDetailSelector);
  const [cardNum, setCardNum] = useState();
  const [cardName, setCardName] = useState();
  const [expDate, setExpDate] = useState();
  const [cvc, setCvc] = useState();
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState();
  const [isError, setError] = useState(false);
  const [errortext, setErrortext] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  console.log(paymentDetail);
  // Event handler for input change
  const handleCardNumberChange = (event) => {
    setCardNum(event.target.value);
    // Remove any non-digit characters from the input
    const rawInput = event.target.value.replace(/\D/g, "");
    let formattedNumber = "";
    for (let i = 0; i < rawInput.length && i <= 19; i++) {
      if (i === 4 || i === 8 || i === 12) {
        formattedNumber += " ";
      }
      formattedNumber += rawInput[i];
    }

    // Update the state with the formatted number
    form.setFieldsValue({ cardnumber: formattedNumber });

    // Implement the logic to detect the card type based on the card number.
    const re = {
      Electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      Maestro:
        /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      Dankort: /^(5019)\d+$/,
      Interpayment: /^(636)\d+$/,
      UniPay: /^(62|88)\d+$/,
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      Master:
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
      American: /^3[47][0-9]{13}$/,
      Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      Jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    };

    for (var key in re) {
      if (re[key].test(rawInput)) {
        return setCardType(key);
      }
    }

    return setCardType("Others");
  };

  // Event handler for input change
  const handleExpirationChange = (event) => {
    setExpDate(event.target.value);
    // Remove any non-digit characters from the input
    const rawInput = event.target.value.replace(/\D/g, "");
    let formattedNumber = "";
    for (let i = 0; i < rawInput.length && i <= 5; i++) {
      if (i === 2) {
        formattedNumber += "/";
      }
      formattedNumber += rawInput[i];
    }

    // Update the state with the formatted number
    form.setFieldsValue({ expirymonthyear: formattedNumber });
  };

  // Event handler for input cvc
  const handleCvc = (e) => {
    setCvc(e.target.value);
  };
  // Event handler for input card name
  const handleName = (e) => {
    setCardName(e.target.value);
  };
  //Event handler for form submit
  const handleFinish = (values) => {
    setLoading(true);
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        const formatCardNos = values.cardnumber.replace(/\s/g, "");
        const [month, year] = values.expirymonthyear.split("/");
        const paymentInfo = {
          userenrollmentid: contact?.userenrollmentid,
          plan_id: planList.plan_id,
          plan_name: planList.plan_name,
          card_type: cardType,
          card_number: formatCardNos,
          cvv: values.cvc,
          expiration_month: month,
          expiration_year: `20${year}`,
          name_on_card: values.name,
        };
        console.log(paymentInfo);
        // API call add make payment
        makePayment(paymentInfo)
          .then((res) => {
            console.log("payment res", res);
            setLoading(false);
            navigate("/successinfo");
          })
          .catch((err) => {
            console.log(err.response.data);
            if (
              err.response.data.success === "Validation Error." ||
              err.response.data.success === false
            ) {
              setError(true);
              setErrortext(err.response.data.message);
              setLoading(false);
            }
            if (err.response.data.message.long_message) {
              setError(true);
              setErrortext(err.response.data.message.long_message);
              setLoading(false);
            }
            setLoading(false);
          });
      });
  };

  useEffect(() => {
    // Handle Date Split
    const renderReduxData = () => {
      // Format Number
      const rawInput = String(contact?.phone).replace(/\D/g, "");
      let formattedNumber = "";
      for (let i = 0; i < rawInput.length && i < 10; i++) {
        if (i === 3 || i === 6) {
          formattedNumber += "-";
        }
        formattedNumber += rawInput[i];
      }
      // Spit date
      form.setFieldsValue({
        firstname: contact?.firstname,
        lastname: contact?.lastname,
        email: contact?.email,
        phonenumber: formattedNumber,
        checked: contact?.contact_declaration === 1 ? true : false,
        ssnno: contact?.ssn,
      });
    };
    renderReduxData();
  }, [form]);

  useEffect(() => {
    setStripePromise(
      loadStripe(
        "pk_live_51O9rBBB7Xwy2xnrDjR9o9aMy38pxGYmLWeLob9x7vw5rJcw7JBSXvZvQh582saet8PsT7VSR2yk0RrjVXmcUxOM300v7d7r83y"
      )
    );
    // stripePubkey()
    //   .then((res) => {
    //     console.log(res.data);
    //     setStripePromise(loadStripe(process.env.STRIPE_PUB_KEY));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  // const options = {
  //   clientSecret: clientSecret,
  // };
  useEffect(() => {
    setLoading(true);

    let paydata = {
      userenrollmentid: contact?.userenrollmentid,
      plan_id: planList[1].plan_id,
      plan_name: planList[1].plan_name,
      plan_amount: paymentDetail.payment_details?.total_amount,
    };
    stripeIntent(paydata)
      .then((res) => {
        const resp = res.data;
        setClientSecret(resp.data.client_secret);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  return (
    <>
    <Spin spinning={loading}>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div
          className="cov-container mb-5"
          style={{
            backgroundColor: "#fff",
            padding: "15px 14px",
            overflow: "scroll",
          }}
        >
          <div className="con-title px-1">PAYMENT DETAILS</div>
          <div className="con-subcontent px-2">
            Please fill out the below details
          </div>
          {isError && (
            <p className="text-danger mb-1" style={{ fontSize: "11px" }}>
              **{errortext}
            </p>
          )}
          {stripePromise && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm />
            </Elements>
          )}
          {/* <Spin spinning={loading} tip="Processing Payment... Please Wait!"> */}
          {/* <Cards
              number={cardNum ?? ""}
              name={cardName}
              expiry={expDate}
              cvc={cvc}
              focused={focus}
            /> */}
          {/* <Form
              className={"px-2 mt-2 custom-x-scrollbar"}
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={handleFinish}
            >
              <Form.Item
                name="cardnumber"
                label="Credit Card Number"
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Card Number Required",
                  },
                ]}
              >
                <Input
                  maxLength={19}
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                  onChange={handleCardNumberChange}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </Form.Item>
              <div className="row">
                <Form.Item
                  name="expirymonthyear"
                  label="Expiration"
                  type="text"
                  className="col"
                  rules={[
                    {
                      required: true,
                      message: "Please enter valid detials",
                    },
                  ]}
                >
                  <Input
                    maxLength={5}
                    className="con-input"
                    placeholder="MM/YY"
                    onChange={handleExpirationChange}
                    onFocus={(e) => setFocus(e.target.name)}
                  />
                </Form.Item>
                <Form.Item
                  value={cvc}
                  name="cvc"
                  label="CVV"
                  className="col"
                  onFocus={(e) => setFocus(e.target.name)}
                  onChange={handleCvc}
                  rules={[
                    {
                      required: true,
                      message: "Atleast 3 character",
                    },
                  ]}
                >
                  <Input
                    value={cvc}
                    type="password"
                    maxLength={4}
                    placeholder="XXX"
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="name"
                label="Name On Card"
                style={{
                  marginTop: "-6px",
                }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  value={cardName}
                  onChange={handleName}
                  onFocus={(e) => setFocus(e.target.name)}
                />
              </Form.Item>
              <Form.Item className="d-flex justify-content-center">
                <Button
                  className="pay-btn enabled"
                  type="primary"
                  htmlType="submit"
                >
                  Pay Now
                </Button>
              </Form.Item>
            </Form> */}
          {/* </Spin> */}
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
      </div>
      <ScreenFooter />
    </Spin>
    </>
  );
}

export default PaymentInfo;