import React, { useState, useEffect } from "react";
import { Button, Input, Space, Spin, message } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCoverage } from "../store/reducers/coverageSlice.js";
import { getCustomerByEmail, validateZipcode } from "../api.js";
import { setContact } from "../store/reducers/contactSlice.js";
import { setAddress } from "../store/reducers/addressSlice.js";
import { setPlan } from "../store/reducers/planSlice.js";

function EnrolHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputPin, setInputPin] = useState("");
  const [email, setEmail] = useState();
  const [isLoading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = useState(true);

  const resetPresist = () => {
    dispatch(setContact([]));
    dispatch(setAddress([]));
    dispatch(setPlan([]));
    localStorage.removeItem("persist:root");
  };

  const handleInputChange = (e) => {
    setInputPin(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleZipcode = () => {
    setLoading(true);
    resetPresist();
    if (inputPin.match(/(\d{5})/g) || inputPin !== "") {
      const validZipcode = {
        zipcode: inputPin,
      };
      validateZipcode(validZipcode)
        .then((res) => {
          console.log("res", res);
          dispatch(setCoverage(res));
          navigate("/coverage");
          message.success("Coverage in your area is Excellent!");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.response.data.message);
        });
    } else {
      message.error("Please Input Valid Zip Code");
      setLoading(false);
    }
  };

  const handleEmail = () => {
    setLoading(true);
    if (email) {
      const validEmail = {
        email: email,
      };
      getCustomerByEmail(validEmail)
        .then((res) => {
          console.log(res.data.data);
          const resp = res.data.data[0];
          resetPresist();
          navigate("/contact");
          dispatch(setContact(resp));
          dispatch(setAddress(resp));
          dispatch(
            setPlan({
              program_name: resp.program_name,
              program_code: resp.program_code,
              userenrollmentid: resp.userenrollmentid,
            })
          );
          dispatch(
            setCoverage({
              state: resp.state,
              zipcode: resp.zipcode,
              city: resp.city,
            })
          );
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.response.data.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
      message.error("Please input valid email address");
    }
  };

  const handleToggle = () => {
    setShow((prevVal) => !prevVal);
  };
  console.log(window.scrollY > 80);

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setScrolled(true);
      console.log(scrolled);
    } else {
      setScrolled(false);
      console.log(scrolled);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* Nav image*/}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          backgroundColor: scrolled ? "rgb(66,75,108,0.8)" : "#101E42",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: "99",
          padding: scrolled ? "0.8rem 1rem" : "1.5rem 2.8rem",
        }}
      >
        <img
          className={scrolled ? "scrolled px-4" : "px-3"}
          src={"assets/images/excess-logo.png"}
          alt="Excess logo"
          style={{
            height: "50px",
            transition: "background-color 0.5s, transform 0.5s",
          }}
        />
        <img
          className={scrolled ? " scrolled header-img" : "header-img"}
          src={"assets/images/equal.png"}
          style={{
            width: "48px",
            height: "28px",
            transition: "background-color 0.5s, transform 0.5s",
          }}
          alt="menu icon"
        />
      </div>
      <div id="main-section"></div>
      {/* content */}
      <Spin spinning={isLoading}>
        <div
          className="container-wrapper container-fluid"
          style={{ marginTop: "5rem" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-4 px-5" style={{marginBottom:97}}>
                <div className="sub-content-ab">
                  Get{" "}
                  <strong className="sub-content-a">
                    Free<sup class="asterisk">*</sup>
                  </strong>
                  <br />
                  Wireless Internet
                </div>
                <div className="sub-content-text">
                And a FREE Smartphone 
                  <sup style={{ fontSize: "16px" }}>*</sup> if you qualify
                </div>
                {/* input pincode */}
                {show ? (
                  <div className="text-left my-4">
                    <div style={{ marginTop: "5px" }}>
                      <Space.Compact className="input-wrapper gap-3">
                        <Input
                          maxLength={5}
                          className="sub-content-input"
                          style={{
                            borderRadius: "40px 40px",
                          }}
                          placeholder="Enter Zip Code to get Started"
                          onChange={handleInputChange}
                        />
                        <Button
                          className="sub-content-button mt-1"
                          type="primary"
                          onClick={handleZipcode}
                          style={{
                            borderRadius: "40px 40px",
                          }}
                        >
                          Apply Now
                        </Button>
                      </Space.Compact>
                    </div>
                    <div className="d-flex toggle-btn">
                      <Button
                        className="tg-btn-enroll"
                        style={{
                          letterSpacing: "3px",
                          color: "#DBFA66",
                          fontSize: "12px",
                          marginTop: "-5px",
                        }}
                        onClick={handleToggle}
                      >
                        Already started? Click
                        <span className="fw-bold mx-2">here</span> to resume.
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ marginTop: "5px" }} className="text-left my-4">
                    <Space.Compact className="input-wrapper gap-3">
                      <Input
                        value={email}
                        type="email"
                        className="sub-content-input"
                        style={{
                          borderRadius: "40px 40px",
                        }}
                        placeholder="Enter Email"
                        onChange={handleEmailChange}
                      />
                      <Button
                        className="sub-content-button ml-2 mt-1"
                        type="primary"
                        onClick={handleEmail}
                        style={{
                          borderRadius: "40px 40px",
                        }}
                      >
                        Resume
                      </Button>
                    </Space.Compact>
                    <div className="d-flex toggle-btn">
                      <Button
                        className="tg-btn-enroll"
                        style={{
                          letterSpacing: "3px",
                          color: "#DBFA66",
                          fontSize: "12px",
                          marginTop: "-5px",
                          "&:hover": {
                            color: "#fe3969 !important",
                          },
                        }}
                        onClick={handleToggle}
                      >
                        Back to New Enrollment !
                      </Button>
                    </div>
                  </div>
                )}
                {/* <div className="sub-content-text-a my-3 col">
                  Apply now through the government <br />
                  Affordable Connectivity Program &#40;ACP&#41;
                </div> */}
                <ul style={{ paddingLeft: 0 }} className="sub-content-ul">
                  <li
                    className="container-cp-li"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "2.2rem",
                      letterSpacing: "1px",
                    }}
                  >
                    <img
                      className="container-cp-img"
                      src={"assets/images/select-yellow.png"}
                      alt="checkImg"
                    />
                    NO CONTRACT
                  </li>
                  <li
                    className="container-cp-li"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "2.2rem",
                      letterSpacing: "1px",
                    }}
                  >
                    <img
                      className="container-cp-img"
                      src={"assets/images/select-yellow.png"}
                      alt="checkImg"
                    />
                    NO CREDIT CHECK
                  </li>
                  <li
                    className="container-cp-li"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "2.2rem",
                      letterSpacing: "1px",
                    }}
                  >
                    <img
                      className="container-cp-img"
                      src={"assets/images/select-yellow.png"}
                      alt="checkImg"
                    />
                    UNLIMITED TALK & TEXT
                  </li>
                </ul>
              </div>
              <div
                className="col-5 section-img-1 display-image px-5"
                style={{ position: "relative" }}
              >
                <img
                  src={"assets/images/new_connected_1.png"}
                  alt="female-with-tablet"
                />
              </div>
            </div>
          </div>
        </div>
      
        {/* SignUP Fast */}
        <div className="container-sup-wrapper px-3 py-5">
          {/* <div className="semi-concave-a"></div> */}
          <div className="text-center container-sup-title  py-3">
            Sign Up Is Fast, Easy and Secure
          </div>
          <div className="container py-3" style={{ background: "none" }}>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3">
                <div className="row align-items-center justify-content-center">
                  <img
                    className="col-6 container-sup-image"
                    src={"assets/images/edit.png"}
                    alt="edit-icon"
                  />
                  <div className="col container-sup-heading">Check Eligibility</div>
                </div>
                <div
                  className="container-sup-sub-heading"
                  style={{ maxWidth: "235px" }}
                >
                  Sign-Up securely in just a few minutes
                </div>
              </div>
              <div className="col-md-4">
                <div className="row align-items-center justify-content-center">
                  <img
                    className="col-6 container-sup-image"
                    src={"assets/images/checked.png"}
                    alt="checked-icon"
                  />
                  <div className="col container-sup-heading">Pick Your Plan</div>
                </div>
                <div
                  className="container-sup-sub-heading"
                  style={{ maxWidth: "400px" }}
                >
                 Select a low or no-cost plan based on your eligibility
                </div>
              </div>
              <div className="col-md-3">
                <div className="row align-items-center justify-content-center">
                  <img
                    className="col-6 container-sup-image"
                    src={"assets/images/mobile-phone.png"}
                    alt="mobile-device"
                  />
                  <div className="col container-sup-heading">Activate</div>
                </div>
                <div className="container-sup-sub-heading">
                  Activate your device and enjoy new broadband internet!
                </div>
              </div>
            </div>
            <div id="who-we-are"></div>
          </div>
        </div>
        {/* How to Qualify*/}
        <div className="container-htq-wrapper">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-2 px-5">
              {/* title */}
              <div className="sub-content-b">HOW TO QUALIFY</div>
              {/* Description */}
              <div className="sub-content-text">
                <br />
                We are dedicated to providing fast, reliable internet service and low-cost devices through the Affordable Connectivity Program (ACP), Lifeline and 
                New Connection Plans.
                <br />
                <br />
                If you or someone in your household is currently enrolled in one of the 
                government-assistance programs listed below, you may be eligible for 
                discounted service and a device. Check to see if you qualify today! 
              </div>
              {/* Description Points */}
              <div className="container-htq-points">
                <li> Federal Public Housing Assistance or Section 8 </li>
                <li> CalFresh, Food Stamps,or SNAP </li>
                <li> Medicaid/Medi-Cal </li>
                <li> Healthy Families Category A</li>
                <li> Low Income Home Energy Assistance Program (LIHEAP)</li>
                <li> Supplemental Security Income (SSI) </li>
                <li> Women, Infants and Children Program (WIC) </li>
                <li> Veterans & Survivor Benefits </li>
                <li> Greater Avenues for Independence (GAIN) </li>
                <li>
                  {" "}
                  Temporary Assistance for Needy Families (TANF) also
                  called:(CalWORKS,StanWORKS,WTW,GAIN)
                </li>
                <li> Welfare-to-Work (WTW)</li>
                <li> Federal Pell Grant Recipient in current award year</li>
                <li> Tribal TANF </li>
                <li> Bureau of Indian Affairs General Assistance</li>
                <li> Head Start Income Eligible (Tribal Only)</li>
                <li> Food Distribution Program on Indian Reservation</li>
                {/* Get Connected Today */}
                <div className="sub-content-text-title pt-2">
                  GET CONNECTED TODAY !
                </div>
                {/* input pincode */}
                <div
                  className="container-cp-input"
                  style={{ paddingBottom: "60px" }}
                >
                  <Space.Compact className="gap-3">
                    <Input
                      maxLength={5}
                      className="sub-content-input"
                      style={{
                        borderRadius: "40px 40px",
                      }}
                      placeholder="Enter Zip Code to get Started"
                      onChange={handleInputChange}
                    />
                    <Button
                      className="sub-content-button"
                      type="primary"
                      onClick={handleZipcode}
                      style={{
                        borderRadius: "40px 40px",
                      }}
                    >
                      Apply Now
                    </Button>
                  </Space.Compact>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <img
                src={"assets/images/how-to-img1.png"}
                alt="looking_at_tablet"
                className="col section-img-2 display-image"
              />
            </div>
          </div>
        </div>
        {/* down arrow */}
        {/* <a href="#who-we-are">
          <div className="semicircle-a">
            <DownOutlined className=" bounce semi-content-a" />
          </div>
        </a> */}
      
        {/* Who we are */}
        <div className="container-wwr-wrapper container-fluid py-3">
          <div className="row">
            <img
              src={"assets/images/smily-guy.png"}
              alt="customer_service"
              className="col-xl-6 col-lg-12 col-md-12 col-sm-12 section-img-3 display-image mt-4 px-5"
            />

            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-4 px-5">
              {/* title */}
              <div className="sub-content-b ">WHO WE ARE</div>
              <div className="sub-content-text-a">
                {/* description */}
                <br />
                Excess Telecom, bringing you fast, easy, and free Internet
                service.
              </div>
              {/* img */}
              <img
                src={"assets/images/excess-logo.png"}
                alt="Excess logo"
                style={{ height: "82px", margin: "2.4em 0" }}
              />
              {/* points */}
              <div className="sub-content-text-p">
                We are on a mission to deliver affordable and accessible premium
                wireless service across America, bringing connectivity to those
                who need it most.
              </div>
              <div className="sub-content-text-p">
                Excess Telecom is now currently one of America&apos;s fastest
                growing wireless companies. Excess Telecom is licensed in all 50
                states and serve nearly 2,000,000 customers.
              </div>
              <div className="sub-content-text-p">
                We exist to ensure that all of our customers receive high
                quality internet service they deserve, an affordable device, and
                the ability to stay connected to what matters most.
              </div>
              <div className="sub-content-text-title">
                GET CONNECTED TODAY !
              </div>
              {/* input pincode */}
              <div
                className="container-cp-input"
                style={{ paddingBottom: "60px" }}
              >
                <Space.Compact className="gap-3">
                  <Input
                    maxLength={5}
                    className="sub-content-input"
                    style={{
                      borderRadius: "40px 40px",
                    }}
                    placeholder="Enter Zip Code to get Started"
                    onChange={handleInputChange}
                  />
                  <Button
                    className="sub-content-button"
                    type="primary"
                    onClick={handleZipcode}
                    style={{
                      borderRadius: "40px 40px",
                    }}
                  >
                    Apply Now
                  </Button>
                </Space.Compact>
              </div>
            </div>
          </div>
          {/* down arrow */}
          <a href="#main-section">
            <div className="semicircle-b">
              <UpOutlined className=" bounce semi-content-b" />
            </div>
          </a>
        </div>
        <div className="semi-concave-b"></div>
        <div className="support-wrapper px-5">
          <div className="container " style={{ background: "none" }}>
            <div className="align-items-center">
              <div className="row" style={{ paddingBottom: "36px" }}>
                {/* logo */}
                <div className="col-md-4 text-center">
                  <img
                    className="support-logo-image-c"
                    src={"assets/images/excess-logo.png"}
                    alt="CJ Grant logo"
                  />
                </div>
                {/* call */}
                <div className="col-md-4 g-3 support-text">
                  <img
                    className="support-logo-image"
                    src={"assets/images/call1.png"}
                    alt="call"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "baseline",
                    }}
                  >
                    <p style={{ fontSize: 16 }}>Enrollment Partner Phone</p>
                    <p className="fw-bold">(412) 981-2323</p>
                  </div>
                </div>
                {/* mail */}
                <div className="col-md-4 g-3 support-text">
                  <img
                    className="support-logo-image"
                    src={"assets/images/email.png"}
                    alt="email"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "baseline",
                    }}
                  >
                    <p style={{ fontSize: 16 }}>Enrollment Partner Email</p>
                    <p>
                      <a
                        className="support-link-text fw-bold"
                        href="mailto:onlineenrollment@cjgrant.com"
                      >
                        onlineenrollment@cjgrant.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* empty line */}
            <div
              className="px-3"
              style={{
                borderBottom: "2px solid #FFF",
                backgroundColor: "none",
              }}
            ></div>
            {/* disclaimer */}
            <div className="support-disclaimer">
            Excess Telecom offers talk, text and broadband services plans through the Lifeline Program. The Lifeline Program is a broadband discount program overseen by the Federal Communications Commission (FCC). Excess Telecom has contracted with IM Telecom to provide support for Lifeline service and benefits and to enable Lifeline subscribers to apply their Lifeline benefits to Excess Telecom branded plans. Qualified individuals enroll their Lifeline service with IM Telecom and receive customer service and support from Excess Telecom. To qualified individuals, Excess Telecom’s Lifeline Standard plan offers 4.5GB of monthly data and a free phone, and the Lifeline Advantage plan offers 6.5GB of monthly data and a free phone. The Lifeline Advantage plan requires an annual payment of $12 at the time of enrollment to cover the monthly $1 service fee. Visit www.excesstelecom.com for full eligibility requirements, service agreement, coverage map, and program details. CJ Grant Connect is a third-party distributor for Excess Telecom and is responsible for the content and creation of this advertisement.

CJ Grant is a third-party distributor for Excess Telecom responsible for this advertisement. Visit  <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="http://www.excesstelecom.com/"
                rel="noreferrer noopenner"
                target="_blank"
              >
                www.excesstelecom.com
              </a>{" "} to review Terms & Conditions and additional plan coverage information.
             
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="d-flex align-items-center footer-wrapper justify-content-center">
          <p className="d-flex  footer-content" style={{ fontSize: "16px" }}>
            {" "}
            &copy; 2024 CJ Grant Sales Solutions.All Rights Reserved.
          </p>
        </div>
      </Spin>
    </>
  );
}

export default EnrolHome;
