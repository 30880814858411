import React, { useEffect, useRef, useState } from "react";
// Axios
import axios from "axios";
// DNR JSX
import { Header } from "./screenHeader";
import ScreenFooter from "./screenFooter";
//  ANT DESIGN
import {
  Button,
  Checkbox,
  Form,
  Input,
  Space,
  message,
  Spin,
  AutoComplete,
  Modal,
} from "antd";
//  ROUTER
import { useNavigate } from "react-router-dom";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { coverageSelector } from "../store/reducers/coverageSlice";
import { contactSelector } from "../store/reducers/contactSlice";
import { addressSelector, setAddress } from "../store/reducers/addressSlice";
import { setCoverage } from "../store/reducers/coverageSlice";
import { setContact } from "../store/reducers/contactSlice";
import { setPlan } from "../store/reducers/planSlice";
import { setEligible } from "../store/reducers/eligibleSlice";
// API
import { postAddress, searchAddress, getReviewDetails } from "../api";

function AddressInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { address } = useSelector(addressSelector);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { coverage } = useSelector(coverageSelector);
  const { contact } = useSelector(contactSelector);
  const [loading, setLoading] = useState(false);
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [options, setOptions] = useState();
  const [diffShipAddress, setDiffShipAddress] = useState(false);
  const [isError, setError] = useState(false);
  const [errortext, setErrortext] = useState("");
  const ref = useRef(null);
  const steps = [
    { completed: true },
    { completed: true },
    { completed: true },
    { completed: false },
  ];

  const scrollStyle = {
    overflowY: diffShipAddress ? "scroll" : "hidden",
    maxHeight: diffShipAddress ? "460px" : "620px",
  };
  // Event Handler to select the Address from Options
  const onSelect = (data) => {
    console.log("onSelect", data);
    setAddLine1(data);
  };
  // Event Handler to search by text
  const handleSearch = (searchText) => {
    let obj_arr = {
      addressline_one: searchText,
      userenrollmentid: contact?.userenrollmentid,
    };
    console.log(obj_arr);
    searchAddress(obj_arr)
      // axios
      //   .get(
      //     `https://expressentry.melissadata.net/web/ExpressAddress?id=KEUUp78ysH-cZ8LcCMMZHM**&line1=${searchText}&maxrecords=10&postalcode=${coverage?.zipcode}&country=US&format=json`
      //   )
      .then((res) => {
        console.log(res);
        const resp = res.data.data;
        console.log(resp);
        const addressLine = resp.map((item) => item.addressline);
        console.log("resp", addressLine);
        const filteredOptions = addressLine.filter((option) =>
          option.toLowerCase().includes(searchText.toLowerCase())
        );
        setOptions(filteredOptions);
      })
      .catch((err) => console.log(err));
  };
  // Event Handler for submit
  const handleFinish = (values) => {
    setLoading(true);
    form
      .validateFields({
        validateOnly: true,
        validateOnly1: true,
      })
      .then(() => {
        let data = {
          userenrollmentid: contact?.userenrollmentid,
          addressline_one: values.addressline1,
          addressline_two: values.addressline2,
          is_differentshippingaddress: values.checked === true ? 1 : 0,
          shippingaddressline_one: values.shippingAddressLine1,
          shippingaddressline_two: values.shippingAddressLine2,
          shippingcity: values.shippingCity,
          shippingstate: values.shippingState,
          shippingzipcode: values.shippingZipcode,
        };
        postAddress(data)
          .then((res) => {
            const resp = res.data.data.telgoo_response;
            console.log(resp);
            const withPhoneData = {
              allow_duplicate_free_phone:
                resp.allow_duplicate_free_phone.toString(),
              free_phone_duplicate_customer_id:
                resp.free_phone_duplicate_customer_id.toString(),
            };
            dispatch(setAddress(data));
            dispatch(setEligible(withPhoneData));
            navigate("/disclosures");
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.response.data.success);
            setLoading(false);
            if (err.response.data.success === "Validation Error.") 
            {
              message.error("Address two must be at least 3 characters")
             
            }
            if (
              err.response.data.message.includes(
                "Duplicate Subscriber::The subscriber in this transaction matches an existing Excess Telecom subscriber."
              )
            ) {
              Modal.error({
                title:
                  "Customer is already registered with this information and, start a new enrollment process.",
                okText: "Start New Enrollment",
                onOk() {
                  navigate("/");
                },
              });
              // message.error("Customer is already registered with this information. and start a new enrollment process.");
              // setError(true);
              // setErrortext("Customer is already registered with this information. and start a new enrollment process.");
            } else if (
              err.response.data.message.includes(
                "Duplicate Subscriber::This customer is already an existing active customer. If the customer believes this is an error please request him to contact customer service."
              )
            ) {
              Modal.error({
                title:
                  "Customer is already registered with this information and, start a new enrollment process.",
                okText: "Start New Enrollment",
                onOk() {
                  navigate("/");
                },
              });
              // message.error("Customer is already registered with this information. and start a new enrollment process.");
              // setError(true);
              // setErrortext("Customer is already registered with this information. and start a new enrollment process.");
            } else if (
              err?.response?.data?.message.some((item) =>
                item.includes(
                  "Default address: The address you entered was found but more information is needed (such as an apartment, suite, or box number) to match to a specific address."
                )
              )
            ) {
              Modal.error({
                title:
                  "The address you entered was found but more information is needed (such as an apartment, suite, or box number)",
                okText: "Close",
                onOk() {},
              });
            } else {
              message.error(err.response.data.message);
            }
          });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const urlId = urlParams.get("userenrollment");
    console.log(urlId);
    // Fetch user data based on the userId
    getReviewDetails({
      userenrollmentid: contact?.userenrollmentid ?? urlId,
    })
      .then((res) => {
        console.log(res);
        const resp = res.data.data;
        dispatch(setContact(resp));
        dispatch(
          setCoverage({
            state: resp.state,
            zipcode: resp.zipcode,
            city: resp.city,
          })
        );
        dispatch(
          setPlan({
            program_name: resp.program_name,
            program_code: resp.program_code,
            userenrollmentid: resp.userenrollmentid,
          })
        );
        dispatch(setAddress(resp));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // message.error(err.response.data.message);
        return;
      });
  }, []);

  // Event Handler for Back
  const handleBackBtn = () => {
    navigate("/governmentprogram");
  };

  const validateForm2Fields = (fieldsValue) => {
    if (!diffShipAddress) {
      return true; // Skip validation when form 2 is hidden
    }
    // Add your validation logic for form 2 fields here
    return fieldsValue.secondField !== undefined;
  };
  const onChange = (e) => {
    console.log(e.target.checked);
    setTimeout(() => {
      if (e.target.checked === true) {
        console.log("scroll fun");
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  useEffect(() => {
    setDiffShipAddress(values?.checked);
  }, [values]);

  useEffect(() => {
    form.setFieldsValue({
      addressline1: address?.addressline_one,
      addressline2: address?.addressline_two,
      checked: address?.is_differentshippingaddress === 1 ? true : false,
      shippingAddressLine1: address?.shippingaddressline_one,
      shippingAddressLine2: address?.shippingaddressline_two,
      shippingCity: address?.shippingcity,
      shippingState: address?.shippingstate,
      shippingZipcode: address?.shippingzipcode,
    });
  }, [Form]);

  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div className="cov-container" style={{ backgroundColor: "#fff" }}>
          <Spin spinning={loading}>
            <div className="con-title px-1">YOUR ADDRESS</div>
            <div className="con-subcontent px-2">Please enter your address</div>
            <Form
              className={"px-2 custom-scrollbar"}
              style={scrollStyle}
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={handleFinish}
            >
              <Form.Item
                name="addressline1"
                label="ADDRESS LINE 1"
                rules={[
                  {
                    required: true,
                    message: "Address Line 1 required",
                  },
                ]}
              >
                <AutoComplete
                  value={addLine1}
                  options={options?.map((option, index) => ({
                    key: index,
                    value: option,
                  }))}
                  onSelect={onSelect}
                  onSearch={handleSearch}
                />
                {/* <Input /> */}
              </Form.Item>
              <Form.Item
                name="addressline2"
                label="ADDRESS LINE 2"
                values={addLine2}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input className="con-input" />
              </Form.Item>
              <Form.Item
                name="city"
                label="CITY"
                initialValue={coverage?.city ?? ""}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled maxLength={20} />
              </Form.Item>
              <Space>
                <Form.Item
                  name="state"
                  label="STATE"
                  initialValue={coverage?.state ?? ""}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input disabled maxLength={2} />
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="ZIPCODE"
                  initialValue={coverage?.zipcode ?? ""}
                  rules={[
                    {
                      pattern: new RegExp("^[0-9]{5}$"),
                      required: true,
                      message: "Invalid",
                    },
                  ]}
                >
                  <Input disabled maxLength={5} />
                </Form.Item>
              </Space>
              <div>
                <Form.Item
                  name="checked"
                  valuePropName="checked"
                  style={{ marginBottom: "-14px" }}
                >
                  <Checkbox
                    defaultChecked={false}
                    onClick={onChange}
                    onChange={(e) => setDiffShipAddress(e.target.checked)}
                    style={{
                      marginTop: 0,
                      backgroundColor: "#fff",
                    }}
                  >
                    I have a different shipping address
                  </Checkbox>
                </Form.Item>
              </div>
              {diffShipAddress && (
                <div ref={ref}>
                  <div className="con-title px-1">SHIPPING ADDRESS</div>

                  <Form.Item
                    name="shippingAddressLine1"
                    label="ADDRESS LINE 1"
                    shouldUpdate={validateForm2Fields}
                    rules={[
                      {
                        required: true,
                        message: "Shipping Address Line 1 required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="shippingAddressLine2" label="ADDRESS LINE 2">
                    <Input className="con-input" />
                  </Form.Item>
                  <Form.Item
                    name="shippingCity"
                    label="CITY"
                    initialValue={coverage?.city ?? address?.city}
                    rules={[
                      {
                        required: true,
                        message: "Shipping Address Line 2 required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Space>
                    <Form.Item
                      name="shippingState"
                      label="STATE"
                      initialValue={coverage?.state ?? address?.state}
                      rules={[
                        {
                          required: true,
                          message: "Shipping State required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="shippingZipcode"
                      label="ZIPCODE"
                      initialValue={coverage?.zipcode ?? address?.zipcode}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]{5}$"),
                          required: true,
                          message: "Invalid Zipcode",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Space>
                </div>
              )}
              <div>
                <Form.Item shouldUpdate>
                  <Space className="d-flex justify-content-center mt-4">
                    <Button
                      className="con-b-btn"
                      htmlType="reset"
                      onClick={handleBackBtn}
                    >
                      Back
                    </Button>
                    <Button
                      className={`con-btn enabled`}
                      type="primary"
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </Form>
            <ul className="dot-steps">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className={`step ${step.completed ? "completed" : ""}`}
                ></li>
              ))}
            </ul>
          </Spin>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default AddressInfo;
