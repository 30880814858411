import React, { useState, useEffect } from "react";
// ANT DESIGN
import { Checkbox, Space, Button, Spin } from "antd";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// ROUTER
import { useNavigate } from "react-router-dom";
import { postDeclaration, getReviewDetails } from "../api.js";
import { useSelector, useDispatch } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import { setContact } from "../store/reducers/contactSlice.js";
import { setCoverage } from "../store/reducers/coverageSlice.js";
import { setPlan } from "../store/reducers/planSlice.js";
import { setAddress } from "../store/reducers/addressSlice.js";

function Disclosures() {
  const navigate = useNavigate();
  const { contact } = useSelector(contactSelector);
  const [currDisclosure, setCurrDisclosure] = useState(0);
  const [checked, setChecked] = useState(false);
  const [disNos, setDisNos] = useState(1);
  const [loading, setLoading] = useState(false);
  const [agreecheck, setAgreeCheck] = useState(true);
  const dispatch = useDispatch();

  const steps = [
    { completed: true },
    { completed: true },
    { completed: true },
    { completed: true },
  ];

  const disclosure = [
    "1.For my household, I affirm and understand that the Affordable Connectivity Program (ACP) is a federal government benefit program operated by the (FCC) that provides discounts on monthly broadband Internet access service and certain connected devices for eligible consumers",
    "2.For my household, I affirm and understand that the Affordable Connectivity Program (ACP) is a federal government benefit program operated by the (FCC) that provides discounts on monthly broadband Internet access service and certain connected devices for eligible consumers",
    "3.For my household, I affirm and understand that the Affordable Connectivity Program (ACP) is a federal government benefit program operated by the (FCC) that provides discounts on monthly broadband Internet access service and certain connected devices for eligible consumers",
    "4.For my household, I affirm and understand that the Affordable Connectivity Program (ACP) is a federal government benefit program operated by the (FCC) that provides discounts on monthly broadband Internet access service and certain connected devices for eligible consumers",
    "5.For my household, I affirm and understand that the Affordable Connectivity Program (ACP) is a federal government benefit program operated by the (FCC) that provides discounts on monthly broadband Internet access service and certain connected devices for eligible consumers",
  ];

  const handleNextDisclosure = () => {
 
      const data = {
        userenrollmentid: contact?.userenrollmentid,
        is_consent: checked,
      };
      setLoading(true);
      postDeclaration(data)
        .then((res) => {
          navigate("/confirminfo");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    
  };
  const handleBackBtn = () => {
   
      // redirect to back page
      navigate("/addressinfo");
   
  };
  

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const urlId = urlParams.get("userenrollment");
    console.log(urlId);
    // Fetch user data based on the userId
    getReviewDetails({
      userenrollmentid: contact?.userenrollmentid ?? urlId,
    })
      .then((res) => {
        console.log(res);
        const resp = res.data.data;
        dispatch(setContact(resp));
        dispatch(
          setCoverage({
            state: resp.state,
            zipcode: resp.zipcode,
            city: resp.city,
          })
        );
        dispatch(
          setPlan({
            program_name: resp.program_name,
            program_code: resp.program_code,
            userenrollmentid: resp.userenrollmentid,
          })
        );
        dispatch(setAddress(resp));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // message.error(err.response.data.message);
        return;
      });
  }, []);
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };

 const handleDisclosure = (e) => { 
  const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
  if (bottom) { 
    console.log("bottom") 
    setAgreeCheck(false);
  }
 }
  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div className="cov-container" style={{ backgroundColor: "#fff", marginBottom:"5px", padding:"5px 0" }}>
          <div className="con-title px-1" style={{paddingTop:"5px"}}>DISCLOSURE AGREEMENTS</div>
          <Spin spinning={loading}>
            <div className="eli-subcontent px-2">
            To continue with your application, please read the following information
            </div>
            <div className="px-2 eli-dob-label">You must scroll to the bottom to enable the checkbox.
</div>
            <div className="px-2">
            <div className="px-2 disclose-container custom-x-scrollbar" onScroll={handleDisclosure} id="scroll-bottom">
            <p><b>Connections Plan</b></p>

<p>The Connections Plan is a broadband internet access program offered by Excess Telecom.</p>

<p>The Connections Plan is separate and distinct from the Affordable Connectivity Program (ACP).</p>

<p>Excess Telecom will assess your eligibility for the Connections Plan using eligibility criteria based upon what would otherwise qualify a household for enrollment in the ACP.</p>

<p>The Connections Plan provides 10 GB of monthly broadband internet data along with 2,500 minutes of talk and 2,500 SMS texts.</p>

<p>The Connections Plan provides four (4) months of free monthly broadband internet coverage to qualifying individuals. Individuals currently enrolled in the ACP are not eligible to enroll in the Connections Plan.</p>

<p>The Connections Plan will end at the conclusion of the 4-month coverage period. Excess Telecom may extend the Connections Plan's 4-month coverage period at its sole discretion. You will receive notification from Excess Telecom of any change to the duration of the Connections Plan.</p>

<p>There is a $12 deferred activation fee that is paid to Excess Telecom at the time of enrollment into the Connections Plan. If the ACP enrollment freeze ends and your ACP benefits are enrolled with Excess Telecom, the deferred $12 activation fee will be applied toward your $12 annual Prepaid Advantage plan payment.</p>

<p>If you terminate your Connections Plan coverage with Excess Telecom prior to the end of the 4-month coverage term and the ACP enrollment freeze remains in-place, Excess Telecom will hold your activation fee for three (3) months in your account. If the ACP enrollment freeze ends and you decide to enroll your ACP benefits with Excess Telecom's Prepaid Advantage plan within this 3-month period, Excess Telecom will apply your Connections Plan activation fee balance toward the annual Prepaid Advantage plan payment. If you don't return to Excess Telecom within this 3-month period, Excess Telecom will keep the activation fee.</p>

<p>If at the end of the Connections Plan's coverage period the ACP enrollment freeze has not ended and the ACP runs out of funding, your Connections Plan service will end and Excess Telecom will keep the activation fee.</p>

<p>A household may file a complaint against Excess Telecom via the process stated in its Terms and Conditions at <a href="www.excesstelecom.com" target="_blank">www.excesstelecom.com.</a></p>

<p>A household may only receive one Connections Plan benefit. To the best of your knowledge, your household is not getting more than one Connections Plan benefit.</p>

<p>You agree that all the information provided through this enrollment process may be collected, used, shared, and retained for the purposes of applying for and/or receiving covered benefits through Excess Telecom. If the laws of your state or Tribal government require it, you agree that the state or Tribal government may share information about your benefits for a qualifying program. The information shared by the state or Tribal government will be used only to help find out if you can get the Connections Plan or ACP benefits.</p>

<p>You certify that you have read and understood the disclosures listed above regarding activation requirements.</p>

<p>You acknowledge that you are providing the information included in this form for Excess Telecom and further authorize Excess Telecom to receive and use your information for enrollment verification and fraud, waste, and abuse mitigation purposes. Also, you authorize Excess Telecom to receive and use your Connections Plan and ACP enrollment information for verification and waste, fraud, and abuse mitigation purposes.</p>

<p>You agree that any state, local, Tribal government, school, or school district, may share information about your receipt of benefits that would establish eligibility for the Connections Plan and ACP, and that such information will be used only to determine program eligibility.</p>

<p>Connections Plan and ACP monthly service plans and one-time device discounts are not transferrable to other households or individuals.</p>

<p>An eligible household does not have to purchase a discounted connected device from Excess Telecom to enroll in the Connections Plan or ACP and receive monthly service discounts.</p>

<p>The Connections Plan and ACP benefits are separate from a Lifeline discount benefit. Lifeline participants may maintain their existing Lifeline services without enrolling in the Connections Plan or ACP.</p>

<p><strong>Disclosures for Selecting a Device</strong></p>

<p>If you are eligible for and would like to receive a tablet device, Excess Telecom will give you a tablet for a copayment of $11 at the time of enrollment into the Connections Plan or ACP. You understand that you must be eligible for an ACP-subsidized device to receive this tablet device offer.</p>

<p>You understand that if your household has already received the one-time ACP device discount through the ACP, you may be eligible to receive a free phone from Excess Telecom. If you are not eligible for a free phone, you will have the option of paying $45 for a new phone or being provided a SIM card to use an existing device to access your plan coverage.</p>

<p>You confirm that you want to purchase and receive a discounted connected device from Excess Telecom if you are eligible for this benefit.</p>

<p>You agree that if you receive a tablet device from Excess Telecom as part of the Connections Plan or ACP, you will pay $11 for the connected device and that, to the best of your knowledge, no one in your household has previously received an ACP-subsidized tablet device from an ACP service provider.</p>

<p><strong>Disclosures for Enrolling Your ACP Benefits into Excess Telecom's Prepaid Advantage Plan upon Ending of the ACP Enrollment Freeze</strong></p>

<p>As of February 8, 2024, there is an ACP enrollment freeze that prevents households from becoming eligible to receive ACP benefits. The Connections Plan provides limited-time broadband coverage to households that would likely have been eligible for the ACP if there was no enrollment freeze.</p>

<p>When the ACP enrollment freeze ends, you agree for Excess Telecom to perform an ACP eligibility check for you with the National Verifier. If you are determined to be ACP eligible, you agree for Excess Telecom to enroll your ACP benefits into the Prepaid Advantage plan. At that time, your deferred $12 Connections Plan activation fee will be placed into your eWallet account with Excess Telecom and $1 applied monthly to cover your Prepaid Advantage plan service fee.</p>

<p>You will have an opportunity to opt-out of being enrolled into the Prepaid Advantage plan when the ACP enrollment freeze ends. Excess Telecom will notify you about the ending of the ACP enrollment freeze and provide you forty-eight (48) hours to opt-out of being enrolled in the Prepaid Advantage plan. As part of Excess Telecom's notification at that time, you will be provided clear instructions about how to opt-out.</p>

<p>You understand that if you have already received the one-time ACP device discount, you may be eligible to receive a free phone from Excess Telecom when enrolling your ACP benefits into the Prepaid Advantage plan unless you received a free phone upon enrollment into the Connections Plan. If you qualify, receipt of a phone with the Prepaid Advantage plan enrollment is subject to a $15 Activation Fee and requires enrollment in the Prepaid Advantage plan. Excess Telecom will defer payment of the Activation Fee for twelve (12) months if you remain enrolled with the provider after which time it will be waived. If your service with Excess Telecom ends within the 12-month period, unused funds in your eWallet will first be applied towards the Activation Fee balance and then any remaining amounts refunded to you.</p>

<p>Excess Telecom may disconnect a household's ACP-supported service after 90 consecutive days of non-payment, if applicable.</p>

<p>A household will be subject to Excess Telecom's undiscounted rates and general terms and conditions if the ACP ends, if you transfer your ACP benefit to another provider but continues to receive service from Excess Telecom, or upon de-enrollment from the ACP.</p>

<p>You agree to notify Excess Telecom within 30 days if you no longer qualify for the ACP. This includes for reasons such as the qualifying person in your household no longer qualifies for ACP through a government program or doesn't meet the income requirements. You will also promptly inform Excess Telecom if your household receives more than one ACP benefit at the same time.</p>

<p>A subscriber already enrolled in the ACP with another provider must consent to the transfer of their ACP benefit to Excess Telecom. You understand that if you are presently enrolled with another ACP provider the effect of an ACP benefit transfer is that your ACP benefit will be applied to Excess Telecom's ACP service and will no longer be applied to services you may continue to receive from your former ACP service provider. You may be subject to your former ACP provider's undiscounted rates due to the transfer if you elect to maintain services from that provider. You are limited to one ACP benefit transfer transaction per service month, with limited exceptions for situations where a subscriber seeks to reverse an unauthorized benefit transfer or is unable to receive service from your current provider.</p>

<p>You are aware of the fully covered ACP service plan available through Excess Telecom.</p>

<p>A household may apply the ACP benefit to any broadband service offering of Excess Telecom, with the exception of the Connections Plan, at the same terms available to households that are not eligible for ACP-supported service.</p>

<p>A household can receive only one ACP benefit and one Lifeline benefit at the same point in time.</p>

<p><strong>Connections Plan and Prepaid Advantage Plan final consents</strong></p>

<p>I certify that I have read, understand, and consent to the disclosures above regarding the Connections Plan.</p>

<p>I certify that I have read, understand, and consent to the disclosures above about having my ACP benefits enrolled into Excess Telecom's Prepaid Advantage plan when the ACP enrollment freeze ends unless I opt-out.</p>

<p>I agree that by submitting the application form and completing the eligibility verification process that I am consenting to enrolling in the Connections Plan with Excess Telecom.</p>

<p><strong>Federal Lifeline Program Benefit</strong></p>

<p>The Federal Lifeline Program, Affordable Connectivity Program (ACP) and Excess Telecom’s Connections Plan are separate programs.  Lifeline benefits, which are provided by Excess Telecom’s affiliate IM Telecom, LLC (IM Telecom), may be combined with ACP (except in California) or Connections Plan benefits and applied to the same Excess Telecom branded service plan.  You do not need to enroll in Lifeline to enroll in ACP or the Connections Plan with Excess Telecom.  You may choose to get Lifeline and ACP services from other service providers.  You may also transfer your Lifeline and ACP services to other providers subject to certain regulatory restrictions.  </p>

<p>By reviewing the disclosures and providing the consents below, you are agreeing to receive Lifeline benefits from IM Telecom and to apply your Lifeline discount benefits to Excess Telecom branded services.  If you are enrolled in ACP with Excess Telecom, you may apply ACP and Lifeline service discounts to the same service plan, except in California. Lifeline services are available only in states where IM Telecom is authorized as a Lifeline provider.  IM Telecom has contracted with Excess Telecom to provide support for Lifeline service and benefits and to enable you to apply those benefits to Excess Telecom branded plans.  Your application of IM Telecom Lifeline program discounts to Excess Telecom branded service is subject to Terms and Conditions available at <a href="www.excesstelecom.com" target="_blank">www.excesstelecom.com.</a>

</p>



<p><strong>Service Activation and Usage Requirement</strong></p>

<p>Excess Telecom offers Lifeline discount benefits with Infiniti Mobile through its agreement with IM Telecom. Eligible subscribers may enroll with Excess Telecom to receive Lifeline services through Infiniti Mobile in states where Infiniti Mobile is approved to enroll Lifeline subscribers. You agree and acknowledge that your Lifeline service will be activated upon submitting the application form and completing the eligibility verification, enrollment and plan selection processes, as applicable. To keep your account active, you must use the service at least once during any 30-day period by completing an outbound call, sending a text message, using cellular data, purchasing additional service from Excess Telecom, answering an in-bound call from someone other than Excess Telecom, or by responding to a direct contact from Excess Telecom confirming that you want to continue receiving service from Excess Telecom. If your service goes unused for 30 days, you will no longer be eligible for Lifeline benefits and your service may be suspended (while allowing 911 calls) subject to a 15-day cure period during which you must use the service (as described above) to fully re-activate your service, keep your telephone number and remain enrolled in Lifeline.</p>

<p>I hereby certify that I have read and understood the disclosures listed above regarding activation and usage requirements.</p>
<p>I authorize Excess Telecom and its affiliate IM Telecom, and their contracted partners for the purpose of applying for, determining eligibility, enrolling in and seeking reimbursement of Lifeline benefits, to collect, use, share, and retain my personal information, including but not limited to information required for the purpose of establishing eligibility for and enrolling in the Lifeline program, and including, but not limited to, full name, full residential address, date of birth, last four digits of social security number, telephone number, eligibility criteria and status, the date on which the Lifeline service discount was initiated and if applicable, terminated, usage status and other compliance requirements, the amount of support being sought for the service, and information necessary to establish identity and verifiable address. This information may be shared with USAC to ensure proper administration of the Lifeline program.  Failure to provide consent will result in me being denied Lifeline benefits and service. </p>

<p><strong>Lifeline Disclosures and Authorizations</strong></p>

<p>Lifeline is a government benefit program operated by the Federal Communications Commission (FCC) that provides discounts on monthly broadband Internet access service and/or voice service. For more information about Lifeline and program eligibility requirements, contact Excess Telecom at 1-800-615-0898 or visit www.excesstelecom.</p>

<p>Willfully making false statements to obtain the benefit can result in fines, imprisonment, de-enrollment or being barred from the Lifeline program.</p>

<p>Only one Lifeline benefit is available per household. A household is not permitted to receive discounted Lifeline services from multiple providers. A household is defined, for purposes of the Lifeline program, as any individual or group of individuals who live together at the same address and share income and expenses. Violation of the one-per-household limitation constitutes a violation of the FCC's rules and will result in you being de-enrolled from the Lifeline program.</p>

<p>Lifeline discount benefits are not transferrable to other households or persons.</p>

<p>Eligibility for Lifeline is determined by the National Verifier and National Lifeline Accountability Database, administered by the Universal Service Administrative Company (USAC), or an alternative verification process approved by the FCC.</p>

<p>Excess Telecom's Terms and Conditions and Privacy Policies are available at <a href="www.excesstelecom.com" target="_blank">www.excesstelecom.com.</a></p>

<p>I hereby certify that I have read and understood the disclosures listed above regarding Lifeline benefits and consent to enroll in Lifeline with Excess Telecom.</p>

<p> Lifeline Eligibility Criteria and Certifications</p>

<p>I certify the following:</p>

<p>I meet the income-based or program-based eligibility criteria for Lifeline as defined in FCC Rule 47 C.F.R. &sect;&#8201;54.409; I will notify Excess Telecom within 30 days if for any reason I no longer satisfy the criteria for receiving the Lifeline benefit, including but not limited to:</p>

<p>I no longer meet the income-based or program-based criteria for receiving Lifeline support,</p>

<p>I am receiving more than one Lifeline benefit, or</p>

<p>Another member of my household is receiving a Lifeline benefit;</p>

<p>I will notify Excess Telecom if I seek to qualify for Lifeline as an eligible resident of Tribal lands as per FCC Rule 47 C.F.R. 54.400(e);</p>

<p>If I move to a new address, I will provide that new address to Excess Telecom within 30 days;</p>

<p>My household will receive only one Lifeline service and, to the best of my knowledge, my household is not already receiving a Lifeline service;</p>

<p>I acknowledge that providing false or fraudulent information to receive Lifeline benefits is punishable by law; and</p>

<p>I acknowledge that I may be required to re-certify my continued eligibility for Lifeline at any time, and my failure to re-certify my continued eligibility will result in de-enrollment and the termination of my Lifeline benefits pursuant to FCC rule 47 C.F.R. &sect;&#8201;54.405(e)(4).</p>

<p><strong>Lifeline Benefit Transfer Consent</strong></p>

<p>A subscriber already enrolled in Lifeline with another provider must consent to the transfer of their Lifeline benefit to Excess Telecom.</p>

<p>The effect of a Lifeline benefit transfer is that your Lifeline benefit will be applied to Excess Telecom's Lifeline service through its agreement with Infiniti Mobile and will no longer be applied to service retained from your former Lifeline service provider. You may be subject to your former Lifeline provider's undiscounted rates as a result of the transfer if you elect to maintain service from that provider.</p>

<p>After reviewing these Lifeline Program disclosures as listed herein, I consent to and authorize the transfer of my current Lifeline benefit to Excess Telecom through Infiniti Mobile, if I am found to already be receiving a Lifeline discount benefit from another Lifeline provider.</p>

<p>General Disclosures for Enrolling with Excess Telecom</p>

<p>I agree to Excess Telecom's Terms &amp; Conditions that are available at <a href="www.excesstelecom.com" target="_blank">www.excesstelecom.com.</a></p>

<p>I authorize and give express consent to being contacted, by Excess Telecom and its affiliates, via email, telephone, or text messaging, including calls or messages using an automated telephone dialing system, manually, or with pre-recorded/artificial voice messages. Excess Telecom and its affiliates may contact me for service-related, informational, or marketing purposes via these methods. I also provide express consent to be contacted, regardless of whether my number is listed on the Do-Not-Call Registry or a state equivalent registry. Consent to such contacts is not a condition of service and may be revoked at any time. Consent for emails, calls and texts is optional and can be revoked at any time by dialing 611 from my Excess Telecom provided wireless number or by calling 1-800-615-0898 and revoking consent. However, I understand that opting out will not affect Excess Telecom's ability to contact me with free notices and messages regarding my wireless service and devices and my benefits via the methods listed herein. For more information see our Terms and Conditions and Privacy Policy at <a href="www.excesstelecom.com" target="_blank">www.excesstelecom.com.</a></p>

<p>I authorize and give express consent for Excess Telecom to use and share my Customer Proprietary Network Information (CPNI) for marketing purposes, including but not limited to sharing my CPNI with affiliates of Excess Telecom. I authorize and give express consent for affiliates of Excess Telecom to contact me about offerings of non-communications-related services, products, and promotions. You have a right, and Excess Wireless has a duty, under federal law, to protect the confidentiality of CPNI. CPNI is information made available to us solely by virtue of our relationship with you that relates to the type, quantity, destination, technical configuration, geolocation data, and amount of use of the telecommunications services you purchase from us, as well as information related to the billing for those services. CPNI does not include subscriber list information, such as name, postal address, or telephone number. You will continue to receive Excess Telecom communications services if you choose to not consent. CPNI is described in our Privacy Policy at <a href="www.excesstelecom.com" target="_blank">www.excesstelecom.com.</a> You may withdraw your consent at any time by reaching us as described in our Privacy Policy.</p>

<p>I authorize Excess Telecom and its contracted partners, for the purpose of applying for, determining eligibility, enrolling in and seeking reimbursement of benefits that I receive through the provider, to collect, use, share, and retain my personal information, including but not limited to information required for the purpose of establishing eligibility for and enrolling in a program with Excess Telecom, and including, but not limited to, full name, full residential address, date of birth, last four digits of social security number, telephone number, eligibility criteria and status, the date on which your service discount was initiated and if applicable, terminated, usage status and other compliance requirements, the amount of support being sought for the service, and information necessary to establish identity and verifiable address. This information may be shared with USAC to ensure proper administration of federal broadband programs. Failure to provide consent will result in me being denied benefits and service through Excess Telecom.</p>

<p><strong>Certification of Truth and Correctness Under Penalty of Perjury</strong></p>

<p>I certify, under penalty of perjury, that the information included in this application and the certifications provided herein are true and correct to the best of my knowledge.</p>

<p><strong>E-sign Certification</strong></p>

<p>I consent to use of this electronic form. I understand I have the right to enroll in the service using non-electronic methods. I further understand that I have the right to withdraw this consent at any time prior to the activation of my service. Excess Telecom has advised me and I understand that I may request a paper copy of my contractual terms and associated fees or withdraw this consent by calling 1-800-615-0898.</p>
                {/* Check this box */}
               
              </div>
              <div className="row" style={{fontSize:12, padding:10}}>
                <div className="col-6"><span style={{color:"#6485f9"}}>Signature</span></div>
                <div className="col-6 text-end"><span style={{color:"#6485f9"}}>Date</span></div>
                <div className="col-6">{contact?.firstname} {contact?.lastname}</div>
                <div className="col-6 text-end">{new Date().toLocaleDateString()}</div>
              </div>
              <Checkbox disabled={agreecheck} onChange={onChange}>I have read and understand these program Disclosures.</Checkbox>
              <Space
                className="d-flex justify-content-center"
                style={{ marginTop: "5px" }}
              >
               
                <Button className="con-b-btn" onClick={handleBackBtn}>
                  Back
                </Button>
                <Button
                  className={`con-btn ${checked ? "enabled" : ""}`}
                  type="primary"
                  disabled={!checked}
                  onClick={handleNextDisclosure}
                >
                  Next
                </Button>
              </Space>
              <ul className="dot-steps" style={{ marginTop: "26px" }}>
                {steps.map((step, index) => (
                  <li
                    key={index}
                    className={`step ${step.completed ? "completed" : ""}`}
                  ></li>
                ))}
              </ul>
            </div>
          </Spin>
        </div>
        {/* -------------------------------- */}

        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default Disclosures;
