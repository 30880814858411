import { useEffect, useState } from "react";
// Ant Design
import { Spin, Carousel, Button, message } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { addDeviceID } from "../store/reducers/planItemSlice.js";
// Image
import tabletImg from "../asset/Tablet-Image.png";

function Device() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState([
    {
      id: 1,
      name: "Tablet",
      image: tabletImg,
      deviceID: 672146721467214,
    },
  ]);
  const [toggleBtn, setToggleBtn] = useState(false);

  const handleNext = () => {
    setloading(true);
    if (selectedDevice.length === 0) {
      message.error("Please select a Device");
      setloading(false);
    }
    if (selectedDevice) {
      // Based on the nlad response 200
      // Choose paidDeviceID or choose freeDeviceID
      // Add to the plan
      dispatch(
        addDeviceID([
          {
            DeviceID: selectedDevice[0].deviceID,
            device_benefit_already_reimbursed: "N",
            is_paid_phone_order: "",
          },
        ])
      );
      navigate("/plan");
      setloading(false);
    }
  };

  return (
    <>
      <div className="cov-wrapper">
        <Header />
        {/* card container view */}
        <div
          className="cov-container mb-5"
          style={{
            backgroundColor: "#fff",
            position: "relative",
            overflow: "scroll",
          }}
        >
          <div className="cov-title" style={{ fontSize: "36px" }}>
            EXCELLENT!
          </div>
          <div className="app-device-subtitle px-3">
            YOU QUALIFY FOR A NEW TABLET
          </div>
          {loading ? (
            <Spin
              style={{
                position: "relative",
                top: 180,
                color: "#fff !important",
              }}
              spinning={loading}
              tip="loading plan ..."
            >
              .
            </Spin>
          ) : (
            <div>
              {selectedDevice?.map((item, index) => {
                return (
                  <div
                    className="d-flex justify-content-center align-items-center py-2 my-4"
                    key={index}
                  >
                    <img
                      className="m-auto"
                      style={{ height: 284 }}
                      src={item.image}
                      alt={`${item.name}`}
                    />
                  </div>
                );
              })}
              <div className="text-center lh-1 px-3 fw-medium">
                A one-time fee of $11 required.
              </div>
              <div className="text-center lh-1 px-3 fw-medium">
                Devices may vary.
              </div>
            </div>
          )}

          {/* {selectedDevice.length === 0 ? null : ( */}
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="plan-btn"
              style={{
               margin: "20px 0",
                borderRadius: "40px 40px",
                padding: "5px 112px",
                backgroundColor: "#dbfa66",
                color: "#101e42",
                fontWeight: "600",
              }}
              onClick={handleNext}
            >
              Continue
            </Button>
          </div>
          {/* )} */}
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default Device;
