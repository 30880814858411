// Redux Toolkit
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
// Redux Presist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// Root Reducer local storage
import rootReducer from "./reducers";

// Persist Config
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({ reducer: persistedReducer });
export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch();

// This will clear the persisted state
// window.addEventListener("beforeunload", () => {
//   persistor.purge();
// });
