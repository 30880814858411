import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: [],
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddress: (state, { payload }) => {
      state.address = payload;
    },
  },
});

export const { setAddress } = addressSlice.actions;
export const addressSelector = (state) => state.address;
export default addressSlice.reducer;
