import React, { useEffect, useState } from "react";
// ANT DESIGN
import { Button, Space, message, Spin } from "antd";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// ROUTER
import { useNavigate } from "react-router-dom";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import { planListSelector } from "../store/reducers/planItemSlice.js";
import { setPaymentDetail } from "../store/reducers/paymentDetailSlice.js";
import { paymentDetailSelector } from "../store/reducers/paymentDetailSlice.js";
import { eligibleSelector } from "../store/reducers/eligibleSlice.js";
// API
import {
  getPlanDetails,
  getReviewDetails,
  createCustomer,
  makePayment,
} from "../api.js";

function PlanDetails() {
  const navigate = useNavigate();
  const { contact } = useSelector(contactSelector);
  const { planList } = useSelector(planListSelector);
  const { eligible } = useSelector(eligibleSelector);
  const { paymentDetail } = useSelector(paymentDetailSelector);
  const [review, setReview] = useState([]);
  const [planReview, setPlanReview] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onBack = () => {
    setLoading(true);
    navigate("/plan");
    setLoading(false);
  };
  const onFinish = () => {
    setLoading(true);
    if (planReview?.payment_details?.total_amount === "0.00") {
      let paymentdetail = {
        userenrollmentid: contact.userenrollmentid,
        plan_id: planList[1].plan_id,
        plan_amount: paymentDetail.payment_details?.total_amount,
        plan_name: planList[1].plan_name,
        payment_status: "succeeded",
        payment_intent: "Free Plan Payment",
        client_secret: "Free Plan Payment",  
      };
      let makePaymentData;
      if (
        eligible.allow_duplicate_free_phone === "YES" &&
        eligible.free_phone_duplicate_customer_id === "0"
      ) {
        makePaymentData = {
          plan_id: planList[1].plan_id,
          plan_name: planList[1].plan_name,
          userenrollmentid: contact.userenrollmentid,
          is_free_phone_order: "Y",
          is_free_phone_order_paid: "N",
          device_benefit_already_reimbursed: "Y",
          model_device_type: "PHONE",
        };
      } else {
        makePaymentData = {
          plan_id: planList[1].plan_id,
          plan_name: planList[1].plan_name,
          userenrollmentid: contact.userenrollmentid,
          is_free_phone_order: "N",
          is_free_phone_order_paid: "N",
          device_benefit_already_reimbursed: "N",
          model_device_type: "TABLET",
        };
      }

      makePayment(makePaymentData)
        .then((res1) => {
          createCustomer(paymentdetail)
            .then((res) => {
              console.log(res);
              const resp = res.data;
              console.log(resp);
              if (resp.success === true) {
                navigate("/successinfo");
              }
            })
            .catch((err1) => {
              message.error(err1.response.data.message);
              setLoading(false);
            });
        })
        .catch((err) => {
          
          if (
            err?.response?.data?.message.some((item) =>
              item.includes("Invoice already Created against this Enrollment ID:")
            )
          ) {
           createCustomer(paymentdetail)
            .then((res) => {
              console.log(res);
              const resp = res.data;
              console.log(resp);
              if (resp.success === true) {
                navigate("/successinfo");
              }
            })
            .catch((err1) => {
              message.error(err1.response.data.message);
              setLoading(false);
            });
          }
          else {
            message.error(err.response.data.message);
            setLoading(false);
          }
        });
    }

    else {
    setLoading(false);
    navigate("/paymentinfo");
    }
  };

  useEffect(() => {
    setLoading(true);
    console.log(planList);
    const planobj = {
      userenrollmentid: contact?.userenrollmentid,
      device_id: planList[0].DeviceID,
      device_benefit_already_reimbursed:
        planList[0].device_benefit_already_reimbursed,
      is_paid_phone_order: planList[0].is_paid_phone_order,
      plan_id: planList[1].plan_id,
      plan_name: planList[1].plan_name,
    };

    getReviewDetails({ userenrollmentid: contact?.userenrollmentid })
      .then((res) => {
        console.log(res);
        setReview(res.data.data);
        // setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });

    getPlanDetails(planobj)
      .then((res) => {
        console.log(res.data.data);
        setPlanReview(res.data.data);
        dispatch(setPaymentDetail(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <Spin spinning={loading}>
            <div className="cov-title" style={{ fontSize: 36 }}>
              PLAN REVIEW
            </div>
            <div className="app-review-subtitle px-1 fw-medium">
              WOW! View your selected <br />
              plan annual cost
            </div>
            {/* Perview Details */}
            {/* <div className="con-subtitle mb-1"></div> */}
            {/* <div className="pay-detail-card custom-x-scrollbar ">
              <div className="row mt-2 px-2 d-flex justify-content-between align-items-center con-card-content ">
                <div className="col-6 fw-bold">Enrollment ID</div>
                <div className="col-6">{planReview?.enrollment_id}</div>
                <div className="col-6 fw-bold">Name</div>
                <div className="col-6">
                  {review.firstname} {review.lastname}
                </div>
                <div className="col-6 fw-bold">City</div>
                <div className="col-6">{review.shippingcity}</div>
                <div className="col-6 fw-bold">State</div>
                <div className="col-6">{review.shippingstate}</div>
                <div className="col-6 fw-bold">Zip Code</div>
                <div className="col-6">{review.shippingzipcode}</div>
                <div className="col-6 fw-bold">Email</div>
                <div className="col-6">{review.email}</div>
                <div className="col-6 fw-bold">Phone Number</div>
                <div className="col-6">{review.phone}</div>
                <div className="col-6 fw-bold">Plan</div>
                <div className="col-6">{planReview?.plan_name}</div>
                <div className="col-6 fw-bold">Plan Price</div>
                <div className="col-6">
                  ${planReview?.payment_details?.total_amount}
                </div>
              </div>
            </div> */}
            <div className="pricing-table pt-3 my-5">
              <div className="ptable-item">
                <div className="ptable-single">
                  <div className="ptable-body">
                    <div className="ptable-description">
                      <div className="prtable-description">
                        {planReview?.plan_name?.toUpperCase()}
                      </div>
                      <div className="fw-bold">
                        {/* ${planReview?.payment_details?.plan?.plan_amount} */}
                      </div>
                      <div
                        style={{
                          fontSize: "40px",
                          margin: "-14px 0 -5px 0",
                          color: "#0978D4",
                        }}
                      >
                        &#43;
                      </div>
                      <div className="prtable-description">
                        &#40;one-time payment&#41;
                        {/* NEW {planReview?.payment_details?.device?.model} */}
                      </div>
                      {/* <div className="fw-bold"> */}
                      {/* ${planReview?.payment_details?.device?.amount} */}
                      {/* &#40;one-time payment&#41; */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="prtable-footer">
                    <div className="prtable-footer-title">TOTAL</div>
                    <div className="prtable-footer-subcontent">
                      {/* ${planReview?.payment_details?.plan?.plan_amount} */}$
                      {planReview?.payment_details?.total_amount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Space className="d-flex justify-content-center mt-4">
              <Button
                className="plan-btn"
                type="primary"
                onClick={onFinish}
                style={{
                  padding: "5px 64px",
                  backgroundColor: "#dbfa66",
                  color: "#101e42",
                  borderRadius: "40px 40px",
                  fontWeight: 600,
                }}
              >
                Continue to Payment
              </Button>
            </Space>
          </Spin>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default PlanDetails;
