import React from "react";

function Badges() {
  return (
    // Badges
    <div className="flex flex-row justify-center gap-3 mt-4 mb-4">
      <img
        src="assets/images/fully-secured-ssl.svg"
        className="max-w-142"
        alt="secured"
      />
      <img src="assets/images/acp.webp" className="h-9/10 my-auto" alt="acp" />
    </div>
  );
}

export default Badges;
