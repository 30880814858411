import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dmdupdate: [],
};

const dmdSlice = createSlice({
  name: "dmdupdate",
  initialState,
  reducers: {
    setdmd: (state, { payload }) => {
      state.dmdupdate = payload;
    },
  },
});

export const { setdmd } = dmdSlice.actions;
export const dmdSelector = (state) => state.dmdupdate;
export default dmdSlice.reducer;
