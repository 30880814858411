import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coverage: {},
};

const coverageSlice = createSlice({
  name: "coverage",
  initialState,
  reducers: {
    setCoverage: (state, { payload }) => {
      state.coverage = payload;
    },
  },
});

export const { setCoverage } = coverageSlice.actions;
export const coverageSelector = (state) => state.coverage;
export default coverageSlice.reducer;
