import { useEffect, useState } from "react";
// Ant Design
import { Button, Progress, Spin } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useSelector } from "react-redux";
import { coverageSelector } from "../store/reducers/coverageSlice.js";
// import { red, yellow, blue, orange, green } from "@ant-design/colors";

function Coverage() {
  // const conicColors = {
  //   "0%": "#6987F0",
  //   "50%": "#FFB804",
  //   "100%": "#FE3969",
  // };
  const navigate = useNavigate();
  const { coverage } = useSelector(coverageSelector);
  const [loading, setloading] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  // Event Handler for Next Button
  const handleNext = (values) => {
    setloading(true);
    navigate("/contact");
    setloading(false);
  };

  useEffect(() => {
    setloading(true);
    setCity(coverage.city);
    setState(coverage.state);
    setloading(false);
  }, [coverage]);

  return (
    <>
      <div className=" cov-wrapper">
        <Header />
        {/* <Spin spinning={loading}> */}
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <div className="cov-title">COVERAGE REPORT</div>
          <div className="cov-subcontent mb-2">
            The coverage in your area is Excellent! <br />
            <span style={{ fontWeight: "bold" }}>
              {city}&#44;{state}
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={"assets/approved-image/coverage-full.png"}
              alt="edit-icon"
              className="mt-4 px-3  "
              style={{ width: 286 }}
            />
          </div>
          <p className="d-flex px-3 mx-0 mt-3 justify-content-between">
            <span className="cov-text">Low</span>
            <span className="cov-text">Medium</span>
            <span className="cov-text">Good</span>
            <span className="cov-excellent">Excellent</span>
          </p>
          {/* <Progress
            className="px-3"
            percent={90}
            showInfo={false}
            strokeColor={conicColors}
          /> */}
          <Progress
            className="px-3"
            showInfo={false}
            percent={90}
            steps={12}
            size={[20, 25]}
          />
          <Button className="cov-btn mt-3" onClick={handleNext}>
            Next
          </Button>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        {/* </Spin> */}
      </div>
      <ScreenFooter />
    </>
  );
}

export default Coverage;
