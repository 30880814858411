import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { LoadingOutlined } from "@ant-design/icons";

import { Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// REDUX
import { useSelector } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import { planListSelector } from "../store/reducers/planItemSlice.js";

import { paymentDetailSelector } from "../store/reducers/paymentDetailSlice.js";
import { eligibleSelector } from "../store/reducers/eligibleSlice.js";
import { dmdSelector } from "../store/reducers/dmdSlice.js";
// API
import { createCustomer, makePayment } from "../api.js";

export default function CheckoutForm() {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const { contact } = useSelector(contactSelector);
  const { planList } = useSelector(planListSelector);
  const { eligible } = useSelector(eligibleSelector);
  const { dmdupdate } = useSelector(dmdSelector);
  const { paymentDetail } = useSelector(paymentDetailSelector);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
   
    setMessage(null);
    e.preventDefault();
    console.log(!stripe || !elements);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    else
    {
      setModal2Open(true);
    }

    

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/successinfo`,
      },
      redirect: "if_required",
    });
    console.log(error);
    console.log(paymentIntent);
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if(error)
    {
      setMessage(error.message);
      setModal2Open(false);
    }
    if (paymentIntent?.last_payment_error) {
      setMessage(paymentIntent.last_payment_error.message);
      setModal2Open(false);
    }
    if (paymentIntent && paymentIntent.status === "succeeded") {
      setIsLoading(false);
     
      let paymentdetail = {
        userenrollmentid: contact.userenrollmentid,
        plan_id: planList[1].plan_id,
        plan_amount: paymentDetail.payment_details?.total_amount,
        plan_name: planList[1].plan_name,
        payment_status: paymentIntent.status,
        payment_intent: paymentIntent.id,
        client_secret: paymentIntent.client_secret,
      };
      let makePaymentData
      if (
        eligible.allow_duplicate_free_phone === "YES" &&
        eligible.free_phone_duplicate_customer_id === "0"
       
      ) {
        if (dmdupdate.Subscriber365DInternalCheckResult === true &&
        dmdupdate.SubscriberСheckResult === true)
        {
          makePaymentData = {
            plan_id: planList[1].plan_id,
            plan_name: planList[1].plan_name,
            userenrollmentid: contact.userenrollmentid,
            is_free_phone_order: "Y",
            is_free_phone_order_paid: "N",
            device_benefit_already_reimbursed: "Y",
            model_device_type: "PHONE",
          };
        }
        else
        {
          makePaymentData = {
            plan_id: planList[1].plan_id,
            plan_name: planList[1].plan_name,
            userenrollmentid: contact.userenrollmentid,
            is_free_phone_order: "N",
            is_free_phone_order_paid: "N",
            device_benefit_already_reimbursed: "Y",
            model_device_type: "PHONE",
          };
        }
        
      }
      else
      {
         makePaymentData = {
          plan_id: planList[1].plan_id,
          plan_name: planList[1].plan_name,
          userenrollmentid: contact.userenrollmentid,
          is_free_phone_order: "N",
          is_free_phone_order_paid: "N",
          device_benefit_already_reimbursed: "N",
          model_device_type: "TABLET",
        };
      }
     

      makePayment(makePaymentData)
        .then((res) => {
          setModal2Open(false);
          navigate('/paymentsuccess', { state: { paymentdetail } });
         // navigate("/paymentsuccess", { paymentdetail });
          //navigate("/paymentsuccess");
          // createCustomer(paymentdetail)
          //   .then((res) => {
          //     console.log(res);
          //     const resp = res.data;
          //     console.log(resp);
          //     if (resp.success === true) {
          //       setModal2Open(false);
          //       navigate("/successinfo");
          //     }
          //   })
          //   .catch((err) => {
          //     setModal2Open(false);
          //     setMessage(err.response.data.message);
          //   });
        })
        .catch((err) => {
          if (
            err?.response?.data?.message.some((item) =>
              item.includes("Invoice already Created against this Enrollment ID:")
            )
          ) {
            setModal2Open(false);
            navigate('/paymentsuccess', { state: { paymentdetail } });
            //console.log(paymentdetail);
          }
          setMessage(err.response.data.message);
          setModal2Open(false);
        });
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        {/* <LinkAuthenticationElement
        className="px-1 pb-3"
        id="link-authentication-element"
        // Access the email value like so:
        onChange={(event) => {
          setEmail(event.value.email);
        }}
        options={{ defaultValues: { email: email } }}
      /> */}
        <PaymentElement id="payment-element" className="px-1 pb-1" />
        {message && (
          <div
            className="mt-2 text-center text-danger fs-8"
            id="payment-message"
          >
            **{message}
          </div>
        )}
        {PaymentElement && (
          <button
            className="mt-2 pay-btn enabled"
            disabled={isLoading || !stripe || !elements}
            id="submit"
          >
            <span id="button-text">
              {isLoading ? "Processing..." : "Pay now"}
            </span>
            <span>
              {"$"}
              {""}
              {paymentDetail.payment_details?.total_amount}
            </span>
          </button>
        )}
        {/* Show any error or success messages */}
      </form>
      <Modal
        title="Payment Processing"
        // centered
        width={350}
        style={{
          top: "40%",
        }}
        footer={null}
        open={modal2Open}
        className="model-payment"
      >
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          }
        />
      </Modal>
    </>
  );
}
