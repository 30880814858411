import React, { useEffect, useState } from "react";
// ANT DESIGN
import { Button, Space, message, Spin, Alert ,Modal } from "antd";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// ROUTER
import { useNavigate } from "react-router-dom";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import { planSelector } from "../store/reducers/planSlice.js";
import { setdmd } from "../store/reducers/dmdSlice.js";
// API
import { getReviewDetails, postEnrollment } from "../api.js";

function ConfirmInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contact } = useSelector(contactSelector);
  const { plan } = useSelector(planSelector);
  const [review, setReview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errortext, setErrortext] = useState("");

  const scrollStyle = {
    overflowY: "scroll",
    maxHeight: "590px",
  };

  useEffect(() => {
    
    const applicationPending = "APPLICATION_PENDING";
    const claimedReimbursed =
      "UNAVAILABLE_DEVICE:- UNAVAILABLE_DEVICE:- Time Device Benefit has already been claimed and reimbursed for this customer.";
    const ineligibleForTransfer =
      "INELIGIBLE_FOR_TRANSFER:- Date eligible for transfer";
    const nlad_down =
      "NLAD seems to be down or isn't responding. Please try your application again soon";
    const enrollment_completed = "enrollment completed";

    const error_applicationPending = {
      data: null,
      msg_code: "RESTAPI110",
      msg: "Error occured",
      errors: ["APPLICATION_PENDING"],
    };
    const error_claimed = {
      data: null,
      msg_code: "RESTAPI110",
      msg: "Error occured",
      errors: [
        "DUPLICATE_SUBSCRIBER_NLAD:- The subscriber in this transaction is a duplicate of another subscriber in the ACP. Select the ACP certification if you choose to transfer this subscriber.",
        "UNAVAILABLE_DEVICE:- UNAVAILABLE_DEVICE:- Time Device Benefit has already been claimed and reimbursed for this customer.",
      ],
    };

    const error_ineligible = {
      data: null,
      msg_code: "RESTAPI110",
      msg: "Error occured",
      errors: [
        "UNAVAILABLE_DEVICE_IN_PROGRESS:- Another provider has already given a one-time device benefit to this subscriber and is still within the administrative window for claiming the benefit. You can offer the subscriber a device after the filing deadline in the following month if the provider does not submit a claim: 09/23. Contact ACProgram@usac.org to dispute.",
        "DUPLICATE_SUBSCRIBER_NLAD:- The subscriber in this transaction is a duplicate of another subscriber in the ACP. Select the ACP certification if you choose to transfer this subscriber.",
        "INELIGIBLE_FOR_TRANSFER:- Date eligible for transfer: 03/01/2023",
      ],
    };

    const error_nlad_down = {
      data: null,
      errors: [
        "NLAD seems to be down or isn't responding. Please try your application again soon. If the error persists, please contact support.",
      ],
      msg: "Error occured",
      msg_code: "RESTAPI110",
      token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InVzZXJuYW1lIjoiRGVtby1UZWxnb281VXNlciIsInBhc3N3b3JkIjoiRGVtby13dEtoeXV0cnduWTYiLCJwaW4iOiJEZW1vLTMyMTY3NDE2NDY1NDQiLCJ2ZW5kb3JfaWQiOiJEZW1vLVRlbGdvbzVkZW1vIiwibG9nX2lkIjozMjU5ODgsInVybCI6Imh0dHBzOi8vZGVtby10ZWxnb281ZGVtby52Y2FyZWNvcnBvcmF0aW9uLmNvbS92Y2FyZU9zc0FwaS8ifSwiZXhwIjoxNjc2NDQ0NDk3fQ.jfJlIPY4xWdJgfp9mkoi2W91TPyHUSfsPsLKRQW6xok",
    };

    const application_pending__result =
      error_applicationPending.errors.includes(applicationPending);
    const claimed_result = error_claimed.errors.some((item) =>
      item.includes(claimedReimbursed)
    );
    const ineligible_result = error_ineligible.errors.some((item) =>
      item.includes(ineligibleForTransfer)
    );
    const nlad_down_results = error_nlad_down.errors.some((item) =>
      item.includes(nlad_down)
    );

    console.log("app pending", application_pending__result);
    console.log("claimed", claimed_result);
    console.log("nlad_down", nlad_down_results);
    console.log("ineligible", ineligible_result);
  }, []);

  const onBack = () => {
    navigate("/disclosures");
  };
  const onFinish = () => {
    setLoading(true);
    postEnrollment({ userenrollmentid: contact?.userenrollmentid })
      .then((res) => {
        const nvLink = res.data.data.link;
        console.log(nvLink)
        console.log(res);
        dispatch(setdmd(res?.data?.data1));
        setLoading(false);
        if (res?.data?.data?.status === "PENDING_RESOLUTION") {
          sessionStorage.setItem("nvlink", res.data.data.link);
          // Change back national verifier path
          // navigate to device just for demo
          // navigate("/device");
          setLoading(false);
          navigate("/nationalverifier");
          
        }
        else if (res?.data?.data?.status === "PENDING_ELIGIBILITY") {
          sessionStorage.setItem("nvlink", res.data.data.link);
          // Change back national verifier path
          // navigate to device just for demo
          // navigate("/device");
          setLoading(false);
          navigate("/nationalverifier");
          
        }
        else if (res?.data?.data?.status === "PENDING_CERT") {
          sessionStorage.setItem("nvlink", res.data.data.link);
          // Change back national verifier path
          // navigate to device just for demo
          // navigate("/device");
          setLoading(false);
          navigate("/nationalverifier");
        }
        else if (res?.data?.data?.status === "PENDING_REVIEW") {
          setLoading(false);
          navigate("/nationalverifier");
          
        }
        else if (res?.data?.data?.status === "COMPLETE") {
          setLoading(false);
          navigate("/deviceselection");
          
        }

        // else if (res.data.data.includes("not eligible to receive a device")) {
        //   // setLoading(false);
        //   // setError(true);
        //   // setErrortext("Sorry! Not eligible to receive a device.");
        //   // message.error("Sorry! Not eligible to receive a device.");
        //   setLoading(false);
        //   setError(false);
        //   Modal.error({
        //     title: 'Customer is already registered with this information and, start a new enrollment process.',
        //     okText: "Start New Enrollment",
        //     onOk() {
        //       navigate("/");
        //     },
            
        //   });        }
        //   else if (res.data.data.includes("Subscriber already transferred with us")) {
           
        //     setLoading(false);
          
        //     Modal.error({
        //       title: 'Customer is already registered with this information and, start a new enrollment process.',
        //       okText: "Start New Enrollment",
        //       onOk() {
        //         navigate("/");
        //       },
              
        //     });        }

        // else if (res.data.message.includes ("Subscriber passed all validations and verifications")) {
        //   setLoading(false);
        //   setError(false);
        //   navigate("/device");
        // }
        else
        {
          message.error(res?.data?.data?.status ?? "Something went wrong please try later");
          setError(true);
          setErrortext(res?.data?.data?.status ?? "Something went wrong please try later");
        }
       
       
      
      })
      .catch((err) => {
        dispatch(setdmd(err?.response?.data?.data1));
        if (err) {
          const duplicateSubscriber = "DUPLICATE_SUBSCRIBER_NLAD:- The subscriber in this transaction is a duplicate of another subscriber in the ACP. Select the ACP certification if you choose to transfer this subscriber."
          const ebb_duplicate = "EBBP_DUPLICATE_ADDRESS:- The primary address in this transaction matches the primary address of another subscriber. The subscriber must complete the household worksheet in the National Verifier. Please direct subscriber to use the following link for the National Verifier to complete the household worksheet:"
          const applicationPending = "APPLICATION_PENDING";
          const claimedReimbursed =
            "UNAVAILABLE_DEVICE:- UNAVAILABLE_DEVICE:- Time Device Benefit has already been claimed and reimbursed for this customer.";
          const ineligibleForTransfer =
            "INELIGIBLE_FOR_TRANSFER:- Date eligible for transfer";
            const service_not_available = "SERVICE_NOT_AVAILABLE:- This service is not allowed at this time.";
          const nlad_down =
            "NLAD seems to be down or isn't responding. Please try your application again soon";
        const subscriber_not_found= "SUBSCRIBER_NOT_FOUND_ERROR:- Subscriber Not Found";
        const cannot_transfer_customer= "CANNOT_TRANSFER_SAME_SAC:- This customer is an existing Excess Telecom Wireless customer";
        const reach_customer ="ACP_APPLICATION_EXISTS:- Existing ACP Application. Please instruct the customer to contact the ACP Hotline at 877-384-2575.  Select options 1, 4, and then 0 to speak to an agent and clear their application.  Once cleared, proceed with the order."
          console.log(err?.response?.data?.message.length === 1);
          if(err?.response?.data?.message.length === 1) 
          {
            if (
              err?.response?.data?.message.some((item) =>
                item.includes(duplicateSubscriber)
              )
            ) {
              setLoading(false);
              navigate("/device");
              // message.success(err?.response?.data?.message);
              return;
            }
          }
         
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(ebb_duplicate)
            )
          ) {
            setLoading(false);
            
            Modal.error({
              title: 'Customer is already registered with this information and, start a new enrollment process.',
              okText: "Start New Enrollment",
              onOk() {
                navigate("/");
              },
              
            });
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(reach_customer)
            )
          ) {
            setLoading(false);
            
            Modal.error({
              title: 'Existing ACP Application. Please contact the ACP Hotline at 877-384-2575. Select options 1, 4, and then 0 to speak to an agent and clear application.',
              okText: "Close",
              onOk() {
                // navigate("/");
              },
              
            });
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(claimedReimbursed)
            )
          ) {
            setLoading(false);
            navigate("/phone");
            // message.success(err?.response?.data?.message);
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(applicationPending)
            )
          ) {
            setLoading(false);
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(ineligibleForTransfer)
            )
          ) {
            setLoading(false);
            
            Modal.error({
              title: 'Customer is already registered with this information and, start a new enrollment process.',
              okText: "Start New Enrollment",
              onOk() {
                navigate("/");
              },
              
            });
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(nlad_down)
            )
          ) {
            setLoading(false);
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(service_not_available)
            )
          ) {
            setLoading(false);
            message.error("Service not available, please try later");
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(subscriber_not_found)
            )
          ) {
            setLoading(false);
            Modal.error({
              title: 'Customer is already registered with this information and, start a new enrollment process.',
              okText: "Start New Enrollment",
              onOk() {
                navigate("/");
              },
              
            });
            // setError(true);
            // setErrortext("Subscriber Not Found");
            // message.error("Subscriber Not Found");
            return;
          }
          if (
            err?.response?.data?.message.some((item) =>
              item.includes(cannot_transfer_customer)
            )
          ) {
            setLoading(false);
            Modal.error({
              title: 'Customer is already registered with this information and, start a new enrollment process.',
              okText: "Start New Enrollment",
              onOk() {
                navigate("/");
              },
              
            });
            // setError(true);
            // setErrortext("Subscriber Not Found");
            // message.error("Subscriber Not Found");
            return;
          }
          setError(true);
          setErrortext(err?.response?.data?.message);
          message.error(err?.response?.data?.message);
        }
        // navigate("/device");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const urlId = urlParams.get("userenrollment");
    console.log(urlId);
    getReviewDetails({ userenrollmentid: contact?.userenrollmentid ?? urlId })
      .then((res) => {
        console.log(res);
        setReview(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <Spin spinning={loading} tip="Please Wait, validating Data ...">
            <div style={scrollStyle} className="custom-x-scrollbar">
              <div className="con-title px-1">ENROLLMENT REVIEW</div>
              <div className="con-subcontent px-1">
                Please review all information is correct and press submit to
                enroll
              </div>
              <div style={{padding: "10px"}}>
              {/* {isError && (
                <Alert style={{ fontSize: "12px" }} message={errortext} type="error" showIcon />
                
              )} */}
              </div>
              {/* Personal Details */}
              <div className="con-subtitle mb-1">PERSONAL</div>
              <div className="con-card custom-x-scrollbar ">
                <div className="row mt-2 px-2 d-flex justify-content-between align-items-center con-card-content ">
                  <div className="col-6 fw-bold">Name</div>
                  <div className="col-6">
                    {review.firstname} {review.middlename} {review.lastname}
                  </div>
                  <div className="col-6 fw-bold">Date of Birth</div>
                  <div className="col-6">{review.dob}</div>
                  <div className="col-6 fw-bold">SSN &#40;last 4&#41;</div>
                  <div className="col-6">{review.ssn}</div>
                  <div className="col-6 fw-bold">Email</div>
                  <div className="col-6">{review.email}</div>
                  <div className="col-6 fw-bold">Phone Number</div>
                  <div className="col-6">{review.phone}</div>
                  <div className="col-6 fw-bold">Government Program</div>
                  <div className="col-6">{plan?.program_code}</div>
                </div>
              </div>
              {/* Address and Shipping */}
              <div className="con-subtitle mt-3 mb-1">
                ADDRESS &amp; SHIPPING
              </div>
              <div className="con-card custom-x-scrollbar ">
                <div className="row mt-2 px-2 d-flex justify-content-between con-card-content ">
                  <div className="col-6 fw-bold">Address</div>
                  <div className="col-6">{review.addressline_one}</div>
                  <div className="col-6 fw-bold">Shipping Address</div>
                  <div className="col-6">{review.shippingaddressline_one}</div>
                  <div className="col-6 fw-bold">City</div>
                  <div className="col-6">{review.shippingcity}</div>
                  <div className="col-6 fw-bold">State</div>
                  <div className="col-6">{review.shippingstate}</div>
                  <div className="col-6 fw-bold">Zip Code</div>
                  <div className="col-6">{review.shippingzipcode}</div>
                </div>
              </div>
              <Space className="d-flex justify-content-center mt-4 mb-4">
                <Button className="con-b-btn" onClick={onBack}>
                  Back
                </Button>
                <Button
                  className={`con-btn enabled`}
                  type="primary"
                  onClick={onFinish}
                >
                  Submit
                </Button>
              </Space>
            </div>
          </Spin>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default ConfirmInfo;
