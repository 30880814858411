import React, { useEffect, useState } from "react";
// Ant Design
import { Button, Space, message, Spin } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
import { useDispatch, useSelector } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import { planSelector, setPlan } from "../store/reducers/planSlice.js";
import { setContact } from "../store/reducers/contactSlice.js";
import { getProgramList, postProgramList, getReviewDetails } from "../api.js";
import { setCoverage } from "../store/reducers/coverageSlice.js";

function GovernmentProgram() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contact } = useSelector(contactSelector);
  const { plan } = useSelector(planSelector);
  const [programlist, setProgramList] = useState();
  const [loading, setLoading] = useState(true);
  const [selProgram, setSelProgram] = useState(null);
  const [selProgramCode, setSelProgramCode] = useState(null);
  const [userId, setUserId] = useState();
  const steps = [
    { completed: true },
    { completed: true },
    { completed: false },
    { completed: false },
  ];

  // Event handler to acquire program
  const handleProgramChange = (code, value) => {
    setSelProgramCode(code);
    setSelProgram(value);
  };
  // Event handler to back btn
  const handleReset = () => {
    setSelProgram("");
    navigate("/contact");
  };
  // Event handler for submit
  const handleSubmit = () => {
    setLoading(true);
    if (selProgram) {
      const data = {
        userenrollmentid: contact?.userenrollmentid,
        program_code: selProgramCode,
        program_name: selProgram,
      };
      postProgramList(data)
        .then((res) => {
          dispatch(setPlan(data));
          navigate("/addressinfo");
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.response.data.message);
          setLoading(false);
        });
    } else {
      message.error("Program not Selected");
    }
  };
  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const urlId = urlParams.get("userenrollment");
    console.log(urlId);
    setUserId(urlId);
    // Fetch user data based on the userId
    getProgramList({
      userenrollmentid: contact?.userenrollmentid ?? urlId,
    })
      .then((res) => {
        console.log(res);
        setProgramList(res.data.data);
        setLoading(false);
        getReviewDetails({
          userenrollmentid: contact?.userenrollmentid ?? urlId,
        })
          .then((res) => {
            console.log(res);
            const resp = res.data.data;
            dispatch(setContact(resp));
            dispatch(setCoverage(resp));
            dispatch(
              setPlan({
                program_name: resp?.program_name,
                program_code: resp?.program_code,
                userenrollmentid: resp?.userenrollmentid,
              })
            );
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            message.error(err.response.data.message);
            return;
          });
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.message);
        return;
      });
  }, []);

  useEffect(() => {
    setSelProgram(plan.program_name);
    setSelProgramCode(plan.program_code);
  }, [plan]);
  return (
    <>
      <div className="cov-wrapper">
        {/* Header */}
        <Header />
        {/* card container view */}
        <div className="cov-container mb-3" style={{ backgroundColor: "#fff" }}>
          <div className="con-title px-1">GOVERNMENT PROGRAM</div>
          <div className="eli-subcontent px-1">
            Please select the government program you currently participate in
          </div>
          <div className="eli-dob-label px-2 mt-2">GOVERNMENT PROGRAM</div>
          <Spin spinning={loading} tip="Please wait...">
            <div className="px-3 mt-4 program-container">
              {/* map function to render options */}
              {programlist?.program_list.map((option, index) => (
                <div
                  key={index}
                  value={option.program_code}
                  className={`pro-item ${
                    selProgramCode === option.program_code ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleProgramChange(
                      option.program_code,
                      option.program_name
                    )
                  }
                >
                  {option.program_name}
                </div>
              ))}
            </div>
          </Spin>
          {/* Button */}
          <Space
            className="d-flex justify-content-center"
            style={{ marginTop: "18%", marginBottom: "9%" }}
          >
            <Button className="con-b-btn" onClick={handleReset}>
              Back
            </Button>
            <Button
              className={`con-btn ${selProgram ? "enabled" : ""}`}
              type="primary"
              disabled={!selProgram}
              onClick={handleSubmit}
            >
              Next
            </Button>
          </Space>
          <ul className="dot-steps mt-3">
            {steps.map((step, index) => (
              <li
                key={index}
                className={`step ${step.completed ? "completed" : ""}`}
              ></li>
            ))}
          </ul>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default GovernmentProgram;
