import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentDetail: [],
};

const paymentDetailSlice = createSlice({
  name: "paymentDetail",
  initialState,
  reducers: {
    setPaymentDetail: (state, { payload }) => {
      state.paymentDetail = payload;
    },
  },
});

export const { setPaymentDetail } = paymentDetailSlice.actions;
export const paymentDetailSelector = (state) => state.paymentDetail;
export default paymentDetailSlice.reducer;
