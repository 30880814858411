import { useState, useEffect } from "react";
// Ant Design
import { Spin, Carousel, Button, message } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { addDeviceID } from "../store/reducers/planItemSlice.js";
import { eligibleSelector } from "../store/reducers/eligibleSlice.js";
import { dmdSelector } from "../store/reducers/dmdSlice.js";
// Image
import phoneImg from "../asset/Phone-Image.png";

function Phone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { eligible } = useSelector(eligibleSelector);
  const { dmdupdate } = useSelector(dmdSelector);
  const [selectedDevice, setSelectedDevice] = useState([
    {
      id: 1,
      name: "Phone",
      image: phoneImg,
      paidDeviceID: 672146721467229,
      freeDeviceID: 672146721467228,
    },
  ]);
  const [toggleBtn, setToggleBtn] = useState(false);

  const handleNext = () => {
    setloading(true);
    let phoneDeviceID;
    if (selectedDevice.length === 0) {
      message.error("Please select a Device");
      setloading(false);
    }
    if (selectedDevice) {
      // Based on the "allow_duplicate_free_phone":"No"
      // Choose paidDeviceID or choose freeDeviceID
      if (
        eligible.allow_duplicate_free_phone === "YES" &&
        eligible.free_phone_duplicate_customer_id === "0" 
      ) {
        phoneDeviceID = {
          DeviceID: selectedDevice[0].freeDeviceID,
          device_benefit_already_reimbursed: "Y",
          is_paid_phone_order: "N",
        };
      } else {
        phoneDeviceID = {
          DeviceID: selectedDevice[0].paidDeviceID,
          device_benefit_already_reimbursed: "Y",
          is_paid_phone_order: "Y",
        };
      }
      dispatch(addDeviceID([phoneDeviceID]));
      navigate("/plan");
      setloading(false);
    }
  };
console.log(dmdupdate)
  return (
    <>
      <div className="cov-wrapper">
        <Header />
        {/* card container view */}
        <div
          className="cov-container mb-5"
          style={{
            backgroundColor: "#fff",
            position: "relative",
            overflow: "scroll",
          }}
        >
          <div className="cov-title mt-1" style={{ fontSize: "18px" }}>
            {/* Use the data from 
                For paid phone
                "free_phone_duplicate_customer_id":"1234"
                "allow_duplicate_free_phone":"No"
                For free phone
                "free_phone_duplicate_customer_id":"1234"
                "allow_duplicate_free_phone":"No"
             */}
            <div className="cov-title" style={{ fontSize: "36px" }}>
              EXCELLENT!
            </div>
            <div className="app-device-subtitle px-3">
              YOU QUALIFY FOR A
              <br />
              {eligible.allow_duplicate_free_phone === "YES" ? "FREE"
                : "PAID"}{" "}
              PHONE
            </div>
          </div>

          {loading ? (
            <Spin
              style={{
                position: "relative",
                top: 180,
                color: "#fff !important",
              }}
              spinning={loading}
              tip="loading plan ..."
            >
              .
            </Spin>
          ) : (
            <div>
              {selectedDevice?.map((item, index) => {
                return (
                  <div
                    className="d-flex justify-content-center align-items-center py-2 my-4"
                    key={index}
                  >
                    <img
                      className="m-auto"
                      style={{ height: 284 }}
                      src={item.image}
                      alt={`${item.name}`}
                    />
                  </div>
                );
              })}
              {eligible.allow_duplicate_free_phone === "YES" ? null : (
                <div className="text-center lh-1 px-3 fw-medium">
                  A one-time fee of $45 required.
                </div>
              )}

              <div className="text-center lh-1 px-3 mt-2 fw-medium">
                Devices may vary.
              </div>
            </div>
          )}

          {/* {selectedDevice.length === 0 ? null : ( */}
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="plan-btn"
              style={{
                marginTop: "25px",
                padding: "5px 102px",
                backgroundColor: "#dbfa66",
                color: "#101e42",
                borderRadius: "40px 40px",
                fontWeight: 600,
              }}
              onClick={() => handleNext()}
            >
              Continue
            </Button>
          </div>
          {/* )} */}
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default Phone;
