import { Header } from "./screenHeader.js";
import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import ScreenFooter from "./screenFooter.js";
import { createCustomer } from "../api.js";



function PaymentSuccessInfo() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentdetail, setPaymentDetail] = useState(null);
  const navigate = useNavigate();
  // Inside your component
const location = useLocation();
const query = location.state.paymentdetail; // 'abc'
    
  useEffect(() => {
    console.log(query)
    setPaymentDetail(query);
   
  }, []);   


  const handleClick = () => {
    setLoading(true);
      createCustomer(paymentdetail)
        .then((res) => {
          console.log(res);
          const resp = res.data;
          console.log(resp);
          setLoading(false);
          if (resp.success === true) {
            navigate("/successinfo");
          }
        })
        .catch((err) => {
          setLoading(false);
          setMessage(err.response.data.message);
        });
   
  
  };
  return (
    <>
     <Spin spinning={loading} tip="Please Wait, validating Data ...">
      <div className=" cov-wrapper">
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
        <div class="main-container">
	<div class="check-container">
		<div class="check-background">
			<svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</div>
		<div class="check-shadow"></div>
	</div>
</div>
          <div className="suc-title" style={{color:"#41d67c"}}>Your payment is successful</div>
          <div className="suc-subcontent mt-3 mb-4 px-2">
            Your enrollment application one step away to approval. Click finish button below proceed further
          </div>
     
          {/* <p className="text-center mt-4 px-3 mx-0 lh-1">
            You will receive an email with follow-up information.
          </p> */}
            {message && (
          <div
            className="mt-2 text-center text-danger fs-8"
            id="payment-message"
          >
            **{message}
          </div>
        )}
          <Button className="suc-btn mb-auto" onClick={handleClick}>
            Finish
          </Button>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
      </Spin>
    </>
  );
}

export default PaymentSuccessInfo;
