import { combineReducers } from "@reduxjs/toolkit";
import coverage from "./coverageSlice";
import contact from "./contactSlice";
import plan from "./planSlice";
import address from "./addressSlice";
import planList from "./planItemSlice";
import paymentDetail from "./paymentDetailSlice";
import eligible from "./eligibleSlice";
import dmdupdate from "./dmdSlice";

const rootReducer = combineReducers({
  coverage,
  contact,
  plan,
  address,
  planList,
  paymentDetail,
  eligible,
  dmdupdate,
});

export default rootReducer;
