import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
// React Router DOM
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Css
import "./index.css";
// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
// Redux-Persist
import { PersistGate } from "redux-persist/integration/react";
// JSX file for each page
import Coverage from "./components/coverage";
import Contact from "./components/contact";
import GovernmentProgram from "./components/governmentprogram";
import AddressInfo from "./components/addressinfo";
import Eligibility from "./components/eligibility";
import PersonalInfo from "./components/personalinfo";
import Disclosures from "./components/disclosures";
import ConfirmInfo from "./components/confirminfo";
import CheckCoverage from "./components/checkcoverage";
import SuccessInfo from "./components/successinfo.js";
import PaymentSuccessInfo from "./components/paymentsuccess.js";
import EnrolHome from "./components/enrolhome";
import NationalVerifier from "./components/nationalVerifier";
import Plan from "./components/plan";
import PlanDetails from "./components/plandetails";
import PaymentInfo from "./components/paymentinfo";
import Device from "./components/device.js";
import DeviceFailure from "./components/devicefailure.js";
import Phone from "./components/phone.js";
import "bootstrap/dist/css/bootstrap.min.css";

// Hide all console in production

// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EnrolHome />} />
          <Route path="/coverage" element={<Coverage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/eligibility" element={<Eligibility />} />
          <Route path="/governmentprogram" element={<GovernmentProgram />} />
          <Route path="/addressinfo" element={<AddressInfo />} />
          <Route path="/personalinfo" element={<PersonalInfo />} />
          <Route path="/disclosures" element={<Disclosures />} />
          <Route path="/confirminfo" element={<ConfirmInfo />} />
          <Route path="/checkcoverage" element={<CheckCoverage />} />
          <Route path="/successinfo" element={<SuccessInfo />} />
          <Route path="/nationalverifier" element={<NationalVerifier />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/planreview" element={<PlanDetails />} />
          <Route path="/paymentinfo" element={<PaymentInfo />} />
          <Route path="/device" element={<Device />} />
          <Route path="/deviceselection" element={<DeviceFailure />} />
          <Route path="/phone" element={<Phone />} />
          <Route path="/paymentsuccess" element={<PaymentSuccessInfo />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

reportWebVitals();
