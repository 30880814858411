import React from "react";

function ScreenFooter() {
  return (
    // Footer
    <div className="container-fluid cov-footer">
  
             <div className="support-disclaimer" style={{fontSize:13, padding:25}}>
             Excess Telecom offers talk, text and broadband services plans through the Lifeline Program. The Lifeline Program is a broadband discount program overseen by the Federal Communications Commission (FCC). Excess Telecom has contracted with IM Telecom to provide support for Lifeline service and benefits and to enable Lifeline subscribers to apply their Lifeline benefits to Excess Telecom branded plans. Qualified individuals enroll their Lifeline service with IM Telecom and receive customer service and support from Excess Telecom. To qualified individuals, Excess Telecom’s Lifeline Standard plan offers 4.5GB of monthly data and a free phone, and the Lifeline Advantage plan offers 6.5GB of monthly data and a free phone. The Lifeline Advantage plan requires an annual payment of $12 at the time of enrollment to cover the monthly $1 service fee. Visit www.excesstelecom.com for full eligibility requirements, service agreement, coverage map, and program details. CJ Grant Connect is a third-party distributor for Excess Telecom and is responsible for the content and creation of this advertisement.

CJ Grant is a third-party distributor for Excess Telecom responsible for this advertisement. Visit  <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="http://www.excesstelecom.com/"
                rel="noreferrer noopenner"
                target="_blank"
              >
                www.excesstelecom.com
              </a>{" "} to review Terms & Conditions and additional plan coverage information.
             
              
            </div>
      {/* Excess Telecom is a service provider for the government-funded Affordable
      Connectivity Program (ACP). The ACP is a government benefit program
      operated by the Federal Communication Commission (FCC) that provides
      discounts on monthly broadband Internet access service and certain
      connected devices. The ACP provides discounts of up to $30 per eligible
      household on monthly broadband Internet access service (or up to $75 per
      eligible household on Tribal lands) and up to $100 on certain connected
      devices (with a required co-payment of between $10 and $50). ACP service
      and device discounts cannot be transferred to another household or
      individual. Eligible households are limited to one monthly service
      discount and one device discount. Rates and device discounts available for
      qualified customers only. Unlimited does not mean unreasonable use. Visit
      www.excesstelecom.com for full eligibility requirements, service
      agreement, coverage map, and program details. CJ Grant is a third-party
      distributor for Excess Telecom and is responsible for the content and
      creation of this advertising and marketing material. */}
    </div>
  );
}

export default ScreenFooter;
