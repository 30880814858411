import { useEffect, useState } from "react";
// Ant Design
import { Spin, Carousel, Button, message,Checkbox } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { contactSelector } from "../store/reducers/contactSlice.js";
import {
  planListSelector,
  setplanList,
} from "../store/reducers/planItemSlice.js";
// API
import { getPlanList } from "../api.js";

function Plan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contact } = useSelector(contactSelector);
  const { planList } = useSelector(planListSelector);
  const [loading, setloading] = useState(false);
  const [plan, setPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [toggleBtn, setToggleBtn] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleNext = () => {
    setloading(true);
    if (selectedPlan.length === 0) {
      message.error("Please select a plan");
      setloading(false);
    }
    if (selectedPlan) {
      console.log(selectedPlan)
      dispatch(setplanList(selectedPlan));
      navigate("/planreview");
      setloading(false);
    }
  };

  const handleClick = (plan) => {
    
    if (plan) {
      setSelectedPlan(plan);
      setToggleBtn(true);
    }
  };

  useEffect(() => {
    setloading(true);
    getPlanList({ userenrollmentid: contact?.userenrollmentid, ordertype: planList[0].name })
      .then((res) => {
        if (planList[0].device_benefit_already_reimbursed === "Y") {
          var restrictedPlan = res?.data?.data?.plan.find(
            (item) =>
              item.plan_id ===
              "2179" /* change in the plan id while switching to production as the plan_id differs */
          );
          setPlan([restrictedPlan]);
          setPlan(res.data.data.plan);
        } else {
          setPlan(res.data.data.plan);
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }, []);
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };
  return (
    <>
     <Spin
              style={{
                position: "relative",
                top: 180,
                color: "#fff !important",
              }}
              spinning={loading}
              tip="loading plan ..."
            >
      <div className="cov-wrapper">
        <Header />
        {/* card container view */}
        <div
          className="cov-container mb-5"
          style={{
            backgroundColor: "#fff",
            position: "relative",
            overflow: "scroll",
          }}
        >
          <div className="cov-title mt-1" style={{ fontSize: "32px" }}>
            SELECT PLAN
          </div>
          <div className="app-plan-subtitle px-3">
            Select the plans listed below.
          </div>
         
           
          
            <Carousel style={{ paddingTop: 20 }}>
              {plan?.map((item, index) => {
                return (
                  <div className="pricing-table" key={index}>
                    <div className="ptable-item">
                      <div
                        className={
                          selectedPlan.plan_id === item.plan_id &&
                          toggleBtn === true
                            ? "ptable-single border-active"
                            : "ptable-single"
                        }
                      >
                        <div className="ptable-header hanging-pane">
                          <div className="ptable-title">
                            <h4>{item.plan_name}</h4>
                          </div>
                          <div className="ptable-price">
                            {/* <h4>${item.total_plan_price}</h4> */}
                          </div>
                        </div>
                        <div className="ptable-body">
                           <h6>Monthly Service Includes:</h6>
                          <div className="ptable-description">
                            <ul className="px-2">
                            <li className="d-flex gap-3 app-plan-li">
                                <img
                                  className="app-plan-img"
                                  src={"assets/images/checked.png"}
                                  alt="checkImg"
                                />
                                <span className="fw-medium">
                                  {item.data / 1000 ?? ""} GB  Data
                                </span>
                              </li>
                              <li className="d-flex gap-3 app-plan-li">
                                <img
                                  className="app-plan-img"
                                  src={"assets/images/checked.png"}
                                  alt="checkImg"
                                />
                                <span className="fw-medium">
                                  {item.display_minute === "" ? item.talk : item.display_minute } Minutes
                                </span>
                              </li>
                              <li className="d-flex gap-3 app-plan-li">
                                <img
                                  className="app-plan-img"
                                  src={"assets/images/checked.png"}
                                  alt="checkImg"
                                />
                                <span className="fw-medium">
                                  {item.display_text === "" ? item.text : item.display_text} Text Messages
                                </span>
                              </li>
                            
                              {/* <li className="d-flex gap-3 app-plan-li">
                                <img
                                  className="app-plan-img"
                                  src={"assets/images/checked.png"}
                                  alt="checkImg"
                                />
                                <span className="fw-medium">
                                  No Monthly Payment
                                </span>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="ptable-footer px-3">
                          <Button
                          
                            onClick={() => handleClick(item)}
                            className="ptable-action"
                            style={{ borderRadius: "40px 40px" }}
                          >
                            {selectedPlan.plan_id === item.plan_id &&
                            toggleBtn === true
                              ? "Selected"
                              : "Select"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>

          

          <Checkbox  onChange={onChange}>I have read and understand the Disclosures for the plan(s) I have selected above and hereby consent to them.</Checkbox>
          {selectedPlan.length === 0 ? null : (
            <div className="d-flex justify-content-center align-items-center">
             
              <Button
               disabled={!checked}
                className="con-btn"
                style={{
                  marginTop: "10px",
                  // backgroundColor: "#dbfa66",
                  // color: "#101e42",
                  fontWeight: 600,
                  borderRadius: "40px 40px",
                  padding: "5px 102px",
                }}
                onClick={handleNext}
              >
                Continue
              </Button>
            </div>
          )}
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
      </Spin>
    </>
  );
}

export default Plan;
