import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  planList: [],
};

const planListSlice = createSlice({
  name: "planList",
  initialState,
  reducers: {
    addDeviceID: (state, { payload }) => {
      state.planList = payload;
    },
    setplanList: (state, { payload }) => {
      state.planList = [...state.planList, payload];
    },
  },
});

export const { addDeviceID, setplanList } = planListSlice.actions;
export const planListSelector = (state) => state.planList;
export default planListSlice.reducer;
