import React from "react";

export const Header = () => {
  return (
    <div>
      <div className=" d-flex mt-3 mb-3 justify-content-center">
        {/* brand img */}
        <img
          className="mt-4"
          src={"assets/approved-image/excess logo.png"}
          alt="Excess logo"
          style={{ width: "290px" }}
        />
      </div>
      {/* <img
        className="header-img header-img-align mt-3 mb-3"
        src={"assets/images/equal.png"}
        alt="Menu Icon"
      /> */}
    </div>
  );
};
