import axios from "axios";

// const URL = "https://phplaravel-958502-3915744.cloudwaysapps.com";
const URL = "https://enrollmentpartner-demo.excesswies.com";
const ET_API = axios.create({
  baseURL: URL,
});

// Stripe
export const stripePubkey = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("http://localhost:8080/api/config")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const stripeIntent = (paydata) => {
  console.log(paydata);
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/makepaymentstripe", paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP Coverage
export const validateZipcode = (zipcode) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/validatezipcode", zipcode)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP CONTACT INFO
export const postContactDetials = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/postcontactinformation", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP ELIGIBILITY DETAILS
export const postEligibility = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/posteligibility", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP GET PROGRAM LIST
export const getProgramList = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/getprogramlist", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP POST PROGRAM LIST
export const postProgramList = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/postprogram", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP POST ADDRESS
export const postAddress = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/postaddress", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const searchAddress = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/validateaddressline", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// ACP POST DECLARATION
export const postDeclaration = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/disclosureagreement", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP POST ENROLLMENT
export const postEnrollment = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/postenrollment", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP GET CUSTOMER DETAILS BY EMAIL
export const getCustomerByEmail = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/getcustomerbymail", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP GET SUMMARY DETAILS
export const getReviewDetails = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/getreviewsummary", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP GET PLAN LIST
export const getPlanList = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/getplanlist", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP GET PLAN DETAILS
export const getPlanDetails = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/paymentdetail", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP MAKE PAYMENT
export const makePayment = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/makepayment", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ACP CREATE LIFELINE CUSTOMER
export const createCustomer = (data) => {
  return new Promise((resolve, reject) => {
    ET_API.post("/api/v1/enrollment/createcustomer", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
