import React, { useState, useEffect } from "react";
// Ant Design
import { Button, Progress, Spin, message, Input } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useDispatch } from "react-redux";
import { setCoverage } from "../store/reducers/coverageSlice.js";
import { setContact } from "../store/reducers/contactSlice.js";
import { setAddress } from "../store/reducers/addressSlice.js";
import { setPlan } from "../store/reducers/planSlice.js";
// API
import { validateZipcode } from "../api.js";

function CheckCoverage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputPin, setInputPin] = useState();
  const [loading, setLoading] = useState(false);
  // Handle InputPin
  const handleInputChange = (e) => {
    setInputPin(e.target.value);
  };
  // Event Handler for Next Button
  const handleNext = () => {
    setLoading(true);
    dispatch(setContact([]));
    dispatch(setAddress([]));
    dispatch(setPlan([]));
    if (inputPin.match(/(\d{5})/g)) {
      const validZipcode = {
        zipcode: Number(inputPin),
      };
      validateZipcode(validZipcode)
        .then((res) => {
          console.log("res", res);
          dispatch(setCoverage(res));
          navigate("/coverage");
          message.success("Coverage Valid");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.response.data.message);
        });
    } else {
      message.error("Please Input Valid Pincode");
      setLoading(false);
    }
  };
  useEffect(() => {
    // Disable the browser's back button
    window.history.pushState(null, null, window.location.pathname);
    // window.addEventListener("popstate", handleNext);

    // return () => {
    //   // Re-enable the back button and remove the event listener when the component unmounts
    //   window.removeEventListener("popstate", handleNext);
    // };
  }, []);
  return (
    <>
      <div className=" cov-wrapper">
        <Header />
        {/* card container view */}
        <div className="cov-container mb-5" style={{ backgroundColor: "#fff" }}>
          <Spin spinning={loading}>
            <div className="cov-title">COVERAGE REPORT</div>
            <div className="cov-subcontent mb-2 px-3">
              Let's Check the coverage in your area <br />
              <Input
                maxLength={5}
                className="mt-2 text-center"
                style={{
                  borderRadius: "40px 40px",
                }}
                placeholder="Enter Zip Code"
                onChange={handleInputChange}
              />
            </div>
            <div className="coverage-img text-center">
              <img
                src={"assets/images/coverage-empty.png"}
                alt="edit-icon"
                className=" px-3 mt-4"
              />
            </div>
            <p className="d-flex px-3 mx-0 mt-3 justify-content-between">
              <span className="cov-text">Low</span>
              <span className="cov-text">Medium</span>
              <span className="cov-text">Good</span>
              <span className="cov-excellent">Excellent</span>
            </p>
            <Progress className="px-3" percent={0} showInfo={false} />
            <Button className="cov-btn mt-3" onClick={handleNext}>
              Check
            </Button>
          </Spin>
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default CheckCoverage;
