import { useEffect, useState } from "react";
// Ant Design
import { Spin, Carousel, Button, message } from "antd";
// Router
import { useNavigate } from "react-router-dom";
// DNR JSX
import { Header } from "./screenHeader.js";
import ScreenFooter from "./screenFooter.js";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { addDeviceID } from "../store/reducers/planItemSlice.js";
import { Radio } from "antd";
// Image
import simImg from "../asset/Sim-Image.png";
import phoneImg from "../asset/Phone-Image1.png";

function Device() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [device, setDevice] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([
    {
      id: 1,
      name: "SIM",
      image: simImg,
      deviceID: "",
    },
    {
      id: 2,
      name: "Phone",
      image: phoneImg,
      deviceID: "",
    },
  ]);
  const [toggleBtn, setToggleBtn] = useState(false);

  const handleNext = () => {
    setloading(true);
    if (device.length === 0) {
      message.error("Please select a Device");
      setloading(false);
      return;
    }
    if ( device[0].name === "SIM") {
      // Based on the nlad response 200
      // Choose paidDeviceID or choose freeDeviceID
      // Add to the plan
      dispatch(
        addDeviceID([
          {
            DeviceID: device[0].deviceID,
            device_benefit_already_reimbursed: "N",
            is_paid_phone_order: "",
            name: device[0].name,
          },
        ])
      );
      navigate("/plan");
      setloading(false);
    }
    else
    {
      dispatch(
        addDeviceID([
          {
            DeviceID: device[0].deviceID,
            device_benefit_already_reimbursed: "N",
            is_paid_phone_order: "",
            name: device[0].name,
          },
        ])
      );
      navigate("/phone");
      setloading(false);
    }
  };

  const handledevice = (e) => {
    console.log(e.target.value, "e.target.value")
    const selected = selectedDevice.filter((item) => item.name === e.target.value);
    setDevice(selected);
  }

  return (
    <>
      <div className="cov-wrapper">
        <Header />
        {/* card container view */}
        <div
          className="cov-container mb-5"
          style={{
            backgroundColor: "#fff",
            position: "relative",
            overflow: "scroll",
          }}
        >
          <div className="cov-title" style={{ fontSize: "36px" }}>
            EXCELLENT!
          </div>
          <div className="app-device-subtitle px-3">
          PLEASE SELECT <br/>A DEVICE TYPE
          </div>
          {loading ? (
            <Spin
              style={{
                position: "relative",
                top: 180,
                color: "#fff !important",
              }}
              spinning={loading}
              tip="loading plan ..."
            >
              .
            </Spin>
          ) : (
            <div>
              <div
                className="d-flex justify-content-center align-items-center py-2 my-4"
               
              >
                <Radio.Group onChange={handledevice} className="radio-custom">
                  {selectedDevice?.map((item, index) => {
                    return (
                      <Radio value={item.name}  key={index} style={{ display: 'flex', alignItems: 'center', marginBottom:20 }}>
                        <img
                          alt=" "
                          width={100}
                          height={100}
                          src={item.image}
                        />
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
           
              <div className="text-center lh-1 px-3 mb-3 fw-medium">
                Devices may vary.
              </div>
            </div>
          )}

          {/* {selectedDevice.length === 0 ? null : ( */}
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="plan-btn"
              style={{
               
                borderRadius: "40px 40px",
                padding: "5px 112px",
                backgroundColor: "#dbfa66",
                color: "#101e42",
                fontWeight: "600",
              }}
              onClick={handleNext}
            >
              Continue
            </Button>
          </div>
          {/* )} */}
        </div>
        {/* -------------------------------- */}
        {/* footer Disclaimer */}
        <ScreenFooter />
      </div>
    </>
  );
}

export default Device;
